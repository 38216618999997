import { useListClustersInfinite } from 'api/frontend-infinite';
import { CLUSTERS } from 'src/data/version_register_cp';
import { clusterFlattenPages } from 'utils/arrays';

export function useClusterIdToNameMap({
  token,
  useExampleData,
}: {
  token: string;
  useExampleData: boolean;
}): Map<string, string> {
  const { data: clustersList } = useListClustersInfinite(
    '',
    {},
    {
      request: { headers: { Authorization: `Bearer ${token}` } },
    },
    {
      initialSize: 100,
      revalidateFirstPage: false,
    },
  );

  const clusterData = clusterFlattenPages(clustersList || []) || [];

  return new Map(
    (useExampleData ? CLUSTERS : clusterData || [])
      .filter((c) => c.internal_k8s_ref !== 'example-cluster-ref')
      .map((c) => [c.id, c.name || '']),
  );
}
