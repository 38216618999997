'use client';

import { useState } from 'react';
import { Account } from 'models/account';
import {
  UpgradePlan,
  UpgradeStageStepStatusDetailsReason,
  UpgradeTemplate,
} from 'api/models';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { updateUpgradeTemplateStageStep } from 'api/frontend';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import {
  ActivityFeedbackFrame,
  Button,
  Dialog,
  IconButton,
  RadioGroup,
  TextArea,
} from 'subframe/index';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
} from 'constants/input-validation';
import * as SubframeCore from '@subframe/core';

interface DoNotMarkForUpgradeDialogProps {
  account: Account;
  upgrade: UpgradePlan | UpgradeTemplate;
  stageNumber: number;
  stepNumber: number;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  variant:
    | 'brand-primary'
    | 'brand-secondary'
    | 'brand-tertiary'
    | 'neutral-primary'
    | 'neutral-secondary'
    | 'neutral-tertiary'
    | 'destructive-primary'
    | 'destructive-secondary'
    | 'destructive-tertiary'
    | 'inverse';
  onConfirm: () => void;
}

function DoNotMarkForUpgradeDialog(props: DoNotMarkForUpgradeDialogProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const doNotMarkForUpgradeSchema = Yup.object().shape({
    reason: Yup.string().required(fieldIsRequiredErrorMessage('Reason')),
    comment: Yup.string()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comment', charLimits.ActionDetails),
      )
      .optional(),
  });
  const defaultValues = {
    reason: '',
    comment: '',
  };

  const methods = useForm({
    resolver: yupResolver(doNotMarkForUpgradeSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    reset,
    clearErrors,
    trigger,
  } = methods;

  const onSubmit = async (data: {
    reason: UpgradeStageStepStatusDetailsReason;
    comment: string;
  }) => {
    props.setIsLoading(true);

    try {
      await updateUpgradeTemplateStageStep(
        props.upgrade.id,
        props.stageNumber.toString(),
        props.stepNumber.toString(),
        {
          status: 'not-marked-for-upgrade',
          status_details: {
            reason: data.reason,
            comment: data.comment,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${props.account.token}`,
          },
        },
      );
      props.onConfirm();
      // we'll wait here for a sec to give the backend some time
      await new Promise((f) => setTimeout(f, 3000));
      enqueueSnackbar(`Successfully marked as 'Don't Upgrade'`, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setDialogOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', `marking as 'Don't Upgrade'`),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      props.setIsLoading(false);
    }
  };

  const resetToDefault = () => {
    reset(defaultValues);
  };

  return (
    <>
      <ActivityFeedbackFrame variant={'default'}>
        <Button
          variant={props.variant}
          size="medium"
          icon="FeatherXSquare"
          disabled={props.isLoading}
          className="flex"
          onClick={(event) => {
            event.preventDefault();
            clearErrors();
            resetToDefault();
            setDialogOpen(true);
          }}
        >
          Don&apos;t Mark for Upgrade
        </Button>
      </ActivityFeedbackFrame>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content>
          <FormProvider
            id={'do-not-mark-for-upgrade-dialog-form'}
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex w-192 grow shrink-0 basis-0 flex-col items-start justify-center gap-6 pt-4 pr-4 pb-4 pl-4">
              <div className="flex w-full items-center justify-between">
                <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                  Don&apos;t Mark for Upgrade?
                </span>
                <IconButton
                  className="h-8 w-8 flex-none"
                  size="medium"
                  icon="FeatherX"
                  onClick={(event) => {
                    setDialogOpen(false);
                    event.preventDefault();
                  }}
                  disabled={props.isLoading}
                />
              </div>
              <div className="flex w-full flex-col items-start justify-center gap-6">
                <div className="flex flex-col items-start gap-1 w-full">
                  <div className="flex w-full flex-col items-start justify-center gap-2">
                    <RadioGroup
                      className="h-auto w-full flex-none"
                      horizontal={true}
                      {...methods.register('reason')}
                      onValueChange={(value: string) => {
                        methods.setValue('reason', value);
                      }}
                    >
                      <RadioGroup.Option
                        label="Will upgrade later"
                        value={
                          UpgradeStageStepStatusDetailsReason.WillUpgradeLater
                        }
                      />
                      <RadioGroup.Option
                        label="Owned by another team"
                        value={
                          UpgradeStageStepStatusDetailsReason.OwnedByAnotherTeam
                        }
                      />
                      <RadioGroup.Option
                        label="Upgrade to different version"
                        value={
                          UpgradeStageStepStatusDetailsReason.UpgradeToADifferentVersion
                        }
                      />
                      <RadioGroup.Option
                        label="Other"
                        value={UpgradeStageStepStatusDetailsReason.Other}
                      />
                    </RadioGroup>
                  </div>
                  {errors?.reason && (
                    <SubframeCore.Text style={{ color: 'var(--error-800)' }}>
                      {errors?.reason?.message}
                    </SubframeCore.Text>
                  )}
                </div>
                <div className="flex w-full flex-col items-start justify-center gap-1">
                  <span className="text-body-bold font-body-bold text-subtext-color">
                    Additional Notes (Optional)
                  </span>

                  <TextArea
                    className="h-auto w-full flex-none"
                    label=""
                    helpText={errors.comment?.message}
                    error={methods.getFieldState('comment').invalid}
                  >
                    <TextArea.Input
                      className="h-auto min-h-[96px] w-full flex-none"
                      placeholder={getValues('comment') || ''}
                      {...methods.register('comment')}
                    />
                  </TextArea>
                </div>
              </div>
              <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 pr-3">
                <div className="flex w-full items-end justify-end gap-4">
                  <Button
                    variant="brand-primary"
                    size="large"
                    icon="FeatherXSquare"
                    type="submit"
                    loading={props.isLoading || isSubmitting}
                    disabled={props.isLoading || isSubmitting}
                    onClick={async () => {
                      const isValid = await trigger();
                      if (isValid) {
                        onSubmit(
                          getValues() as {
                            reason: UpgradeStageStepStatusDetailsReason;
                            comment: string;
                          },
                        );
                      }
                    }}
                  >
                    Don&apos;t Upgrade
                  </Button>
                </div>
              </div>
            </div>
          </FormProvider>
        </Dialog.Content>
      </Dialog>
    </>
  );
}

export default DoNotMarkForUpgradeDialog;
