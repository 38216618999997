'use client';
import * as SubframeCore from '@subframe/core';
import {
  Alert,
  Badge,
  Breadcrumbs,
  Button,
  InformationalHeader,
  Switch,
} from 'subframe/index';
import Page from 'components/Page';
import {
  updateUpgradeTemplate,
  useListSubscriptions,
  useListUpgradeTemplates,
} from 'api/frontend';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import useUserAccountState from 'hooks/useUserAccountState';
import { ResolvedEntitlementFeaturesItem } from 'api/models';
import { ConsoleLoader } from 'components/Loader';
import { getHumanReadableDate } from 'utils/subscription_plan';
import BaseTable from 'components/design-system/Table/BaseTable';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { AtomicTooltip } from 'components/design-system';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { RouterLink } from 'components/RouterLink';
import {
  getTooltipForUpgradeRequestedBy,
  showCancel,
  UpgradeTemplateStatusBadge,
} from '../helpers/UpgradesHelpers';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { LockingWrapper } from 'components/LockingWrapper';
import useLocalStorage from 'hooks/useLocalStorage';
import { UPGRADE_TEMPLATES } from 'src/data/upgrades';
import { Searchbar } from 'components/design-system/Searchbar';
import { CommentTooltip } from 'components/design-system/CommentTooltip';
import { UpgradesMoreOptions } from '../components/UpgradesMoreOptions';
import MarkUpgradeTemplateAsApproved from '../components/MarkUpgradeTemplateAsApproved';
import RegenerateTemplateDialog from '../components/RegenerateTemplateDialog';
import { filterUpgradeTemplatesByResources } from 'utils/upgrades';
import { useListClustersInfinite } from 'api/frontend-infinite';
import { clusterFlattenPages } from 'utils/arrays';

function AddonUpgradeTemplatesLandingPage() {
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const { account, currentOrganization } = useUserAccountState();
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );

  const addonUpgradeTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates/addons',
  );
  const addonUpgradesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/plans/addons',
  );
  const clustersBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  useEffect(() => {
    logEvent('upgrade-templates-addons-page-view', {
      showExampleData,
    });
  }, [showExampleData]);

  //Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization?.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
  );

  // Clusters
  const { data: clustersList } = useListClustersInfinite(
    '',
    {},
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
    {
      initialSize: 100,
      revalidateFirstPage: false,
    },
  );

  const clusters = clusterFlattenPages(clustersList || []) || [];

  const realClusters = clusters
    ? clusters.filter((c) => c.internal_k8s_ref !== 'example-cluster-ref')
    : [];

  useEffect(() => {
    if (clusters && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusters.length);
    }
  }, [clusters]);

  // Upgrade Templates
  const { data: upgradeTemplatesData, mutate } = useListUpgradeTemplates(
    {},
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  const exampleTemplates =
    filterUpgradeTemplatesByResources(UPGRADE_TEMPLATES).addonUpgradeTemplates;
  const upgradeTemplates = showExampleData
    ? exampleTemplates
    : upgradeTemplatesData
    ? filterUpgradeTemplatesByResources(
        upgradeTemplatesData.data,
      ).addonUpgradeTemplates.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Template should appear on top
      })
    : [];
  const [searchTemplate, setSearchTemplate] = useState('');
  const [templatesToShow, setTemplatesToShow] = useState(upgradeTemplates);

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchTemplate(newValue);
  };
  useDebounce(
    () => {
      if (searchTemplate) {
        logEvent('search-upgrade-templates', { searchTemplate });
      }
    },
    1000,
    [searchTemplate],
  );
  useEffect(() => {
    if (searchTemplate !== '') {
      const found = upgradeTemplates.filter((item) =>
        item?.name?.toLowerCase().includes(searchTemplate.toLowerCase()),
      );
      setTemplatesToShow(found);
    } else {
      setTemplatesToShow(upgradeTemplates);
    }
  }, [upgradeTemplatesData, searchTemplate, showExampleData]);

  const showCreateExampleTemplateToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const exampleTemplateCreateToast =
      'No new Upgrade Templates can be created from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(exampleTemplateCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };

  const showCreateExamplePlanToast = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const examplePlanCreateToast =
      'No new Upgrade Plans can be instantiated from example data';
    event.stopPropagation();
    event.preventDefault();
    enqueueSnackbar(examplePlanCreateToast, {
      variant: 'warning',
      autoHideDuration: toastAutoHideDuration,
    });
  };
  // by default show lock is true
  const showUpgradePlanLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_template',
      )
    : true;

  return (
    <Page title="Add-on Upgrade Templates">
      <div className="flex h-full flex-col items-start gap-6 bg-default-background px-3 pt-8 ml-8 w-[calc(100% - 32px]">
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item>Upgrade Templates</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Add-ons</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex w-full items-end justify-between">
            <span className="text-section-header font-section-header text-default-font">
              Add-on Upgrade Templates
            </span>
            {realClusters.length ? (
              <RouterLink to={`${addonUpgradeTemplatesBasePath}/new`}>
                <LockingWrapper
                  feature={
                    ResolvedEntitlementFeaturesItem.request_upgrade_template
                  }
                  showLock={showTemplateLock}
                  tooltipText={
                    showTemplateLock
                      ? undefined //default, handled in LockingWrapper
                      : 'Request Add-on Upgrade Template'
                  }
                >
                  <Button
                    disabled={false}
                    size="large"
                    icon="FeatherPlus"
                    iconRight={showTemplateLock ? 'FeatherLock' : null}
                  >
                    Request Add-on Upgrade Template
                  </Button>
                </LockingWrapper>
              </RouterLink>
            ) : (
              <LockingWrapper
                feature={
                  ResolvedEntitlementFeaturesItem.request_upgrade_template
                }
                tooltipText="Add a cluster to request Add-on Upgrade Template"
                showLock={showTemplateLock}
              >
                <Button
                  size="large"
                  icon="FeatherPlus"
                  disabled={true}
                  iconRight={showTemplateLock ? 'FeatherLock' : null}
                >
                  Request Add-on Upgrade Template
                </Button>
              </LockingWrapper>
            )}
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-body font-body text-subtext-color">
              Overview of all requested Add-on Upgrade Templates
            </span>
            <div className="flex grow shrink-0 basis-0 items-center justify-end gap-2">
              <span className="text-body font-body text-subtext-color">
                Show example data
              </span>
              <AtomicTooltip
                data-cy="show-example-data-tooltip"
                tooltipContent={
                  showExampleData
                    ? 'Show results from your onboarded clusters'
                    : 'Show example data'
                }
              >
                <Switch
                  data-cy="switch"
                  checked={showExampleData}
                  onCheckedChange={() => {
                    setShowExampleData(!showExampleData);
                    setSearchTemplate('');
                  }}
                />
              </AtomicTooltip>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        {!showExampleData &&
        (clusters === undefined || upgradeTemplatesData === undefined) ? (
          <ConsoleLoader />
        ) : (
          <>
            {showExampleData ||
            realClusters.length ||
            upgradeTemplates.length ? (
              <div className="flex w-full flex-col items-start gap-2">
                <span className="text-subheader font-subheader text-default-font">
                  Current Add-on Upgrade Templates
                </span>
                {upgradeTemplates.length ? (
                  <>
                    <div className="flex w-full grow shrink-0 basis-0 items-end justify-between">
                      <InformationalHeader className="flex-[1_0_0] h-full w-full">
                        <InformationalHeader.Item
                          data-cy="total-count"
                          title="Total"
                          bodySlot={
                            <span className="text-body-bold font-body-bold text-default-font">
                              {upgradeTemplates.length || 0}
                            </span>
                          }
                        />
                        {(upgradeTemplates || []).filter(
                          (ut) => ut.status === 'pending',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="pending-count"
                            title="Waiting for Template"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (upgradeTemplates || []).filter(
                                    (ut) => ut.status === 'pending',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(upgradeTemplates || []).filter(
                          (ut) => ut.status === 'in-progress',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="inprogress-count"
                            title="Available"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (upgradeTemplates || []).filter(
                                    (ut) => ut.status === 'in-progress',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(upgradeTemplates || []).filter(
                          (ut) => ut.status === 'regenerate-in-progress',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="regenerating-count"
                            title="Regenerating Template"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (upgradeTemplates || []).filter(
                                    (ut) =>
                                      ut.status === 'regenerate-in-progress',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(upgradeTemplates || []).filter(
                          (ut) => ut.status === 'approved',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="approved-count"
                            title="Approved for Use"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (upgradeTemplates || []).filter(
                                    (ut) => ut.status === 'approved',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                        {(upgradeTemplates || []).filter(
                          (ut) => ut.status === 'cancelled',
                        ).length > 0 && (
                          <InformationalHeader.Item
                            data-cy="cancelled-count"
                            title="Canceled"
                            bodySlot={
                              <span className="text-body-bold font-body-bold text-default-font">
                                {
                                  (upgradeTemplates || []).filter(
                                    (ut) => ut.status === 'cancelled',
                                  ).length
                                }
                              </span>
                            }
                          />
                        )}
                      </InformationalHeader>

                      <Searchbar
                        onChange={handleOnChange}
                        view={showExampleData ? 'example' : 'real'}
                      />
                    </div>
                    <div className="flex w-full flex-col items-start gap-12">
                      <BaseTable
                        settings={{
                          localStorageKey: 'addon_upgrade_templates_table',
                          dataCyPrefix: 'upgrades-templates-table',
                        }}
                        data={templatesToShow}
                        noMatchingData={Boolean(
                          upgradeTemplates.length && !templatesToShow.length,
                        )}
                        rowLink={(template) =>
                          showExampleData &&
                          template.id !==
                            'upgtmpl_54d5a2db-144e-40eb-a828-5757dddec241'
                            ? ''
                            : `${addonUpgradeTemplatesBasePath}/${template.id}`
                        }
                        rowSettings={{
                          rowTooltip: (template) =>
                            template.status === 'pending'
                              ? UpgradeTemplateStatusBadge[template.status]
                                  ?.tooltip || ''
                              : '',
                          preventClick: (template) =>
                            template.status === 'pending' ||
                            (showExampleData &&
                              template.id !==
                                'upgtmpl_54d5a2db-144e-40eb-a828-5757dddec241'),
                          preventClickToastMessage: showExampleData
                            ? 'This is a demonstration view only at this point - only some Upgrade Templates are available'
                            : "This Upgrade Template is being generated. You'll be notified when it's published",
                        }}
                        columns={[
                          {
                            id: 'template',
                            title: 'Template',
                            cellStyle: {
                              width: '30%',
                              height: '110px',
                              flex: '0 0 auto',
                            },
                            cellType: 'cell',
                            render: (template) => {
                              return (
                                <div className="flex items-start gap-2 ">
                                  <div className="flex flex-col items-start gap-2">
                                    <AtomicTooltip
                                      tooltipContent={template.name}
                                    >
                                      <div className="truncate-ellipsis ">
                                        <span className="text-body-bold font-body-bold text-default-font">
                                          {template.name}
                                        </span>
                                      </div>
                                    </AtomicTooltip>
                                    <div className="flex items-start gap-2">
                                      {template.current_version ? (
                                        <span className="text-label font-label text-subtext-color">
                                          {`From: ${template.current_version}`}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {template.desired_version ? (
                                        <span className="text-label font-label text-subtext-color">
                                          {`To: ${template.desired_version}`}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>

                                    {template.last_validated ? (
                                      <span className="text-label font-label text-subtext-color">
                                        {`Preverified: ${getHumanReadableDate(
                                          template.last_validated,
                                        )}`}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {template.status === 'completed' &&
                                    template.status_comment && (
                                      <CommentTooltip
                                        userName={template?.completed_by?.name}
                                        picture={
                                          template?.completed_by?.picture
                                        }
                                        time={
                                          template?.updated
                                            ? template?.updated / 1000
                                            : undefined
                                        }
                                        comment={template?.status_comment}
                                      />
                                    )}
                                </div>
                              );
                            },
                          },
                          {
                            id: 'status',
                            title: 'Status',
                            cellType: 'cell',
                            cellStyle: {
                              width: '10%',
                            },
                            render: (template) => {
                              return (
                                <AtomicTooltip
                                  tooltipContent={
                                    UpgradeTemplateStatusBadge[template.status]
                                      .tooltip ||
                                    UpgradeTemplateStatusBadge[template.status]
                                      .title
                                  }
                                >
                                  <Badge
                                    variant={
                                      UpgradeTemplateStatusBadge[
                                        template.status
                                      ].variant
                                    }
                                    style={{
                                      maxWidth: '150px',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {
                                      UpgradeTemplateStatusBadge[
                                        template.status
                                      ].title
                                    }
                                  </Badge>
                                </AtomicTooltip>
                              );
                            },
                          },
                          {
                            id: 'updated',
                            title: 'Last Updated',
                            cellType: 'cell',
                            cellStyle: {
                              width: '10%',
                            },
                            render: (template) => {
                              return (
                                <span className="text-label font-label text-subtext-color">
                                  {template.last_validated
                                    ? getHumanReadableDate(
                                        template.updated
                                          ? template.updated
                                          : template.last_validated,
                                      )
                                    : ''}
                                </span>
                              );
                            },
                          },
                          {
                            id: 'requested-by',
                            title: 'Requested by',
                            cellType: 'cell',
                            cellStyle: {
                              width: '10%',
                            },
                            render: (template) => {
                              return (
                                <AtomicTooltip
                                  className="ph-no-capture"
                                  tooltipContent={getTooltipForUpgradeRequestedBy(
                                    template.requested_by?.name,
                                    template.created,
                                    true,
                                  )}
                                >
                                  <span className="text-body font-body text-default-font ph-no-capture">
                                    {template.requested_by?.name}
                                  </span>
                                </AtomicTooltip>
                              );
                            },
                          },
                          {
                            id: 'approved',
                            title: 'Approved',
                            cellType: 'cell',
                            cellStyle: {
                              width: '15%',
                            },
                            render: (template) => {
                              return (
                                <>
                                  {template.status === 'approved' &&
                                  template.approved ? (
                                    <div className="flex items-start gap-1">
                                      <span className="text-body font-body text-subtext-color">
                                        {getHumanReadableDate(
                                          template.approved,
                                        )}
                                      </span>
                                      <CommentTooltip
                                        className="ph-no-capture"
                                        userName={template?.approved_by?.name}
                                        picture={template?.approved_by?.picture}
                                        time={template.approved / 1000}
                                        comment={template?.status_comment || ''}
                                        action="approved this Upgrade Template"
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            },
                          },
                        ]}
                        actions={[
                          {
                            id: 'actions',
                            render: (template) => {
                              return (
                                <div className="flex grow shrink-0 basis-0 items-center justify-end gap-2 self-stretch">
                                  <div
                                    className="flex items-center justify-end pr-2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    onContextMenu={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    {template.status === 'approved' && (
                                      <RouterLink
                                        to={`${addonUpgradesBasePath}/new?template_id=${template.id}`}
                                      >
                                        <LockingWrapper
                                          feature={
                                            ResolvedEntitlementFeaturesItem.request_upgrade_plan
                                          }
                                          tooltipText={
                                            showUpgradePlanLock
                                              ? ''
                                              : 'Instantiate Upgrade Plan'
                                          }
                                          showLock={showUpgradePlanLock}
                                          showExampleData={showExampleData}
                                          defaultAction={(event) => {
                                            if (showExampleData) {
                                              showCreateExamplePlanToast(event);
                                            }
                                          }}
                                        >
                                          <Button
                                            disabled={false}
                                            variant="brand-secondary"
                                            size="medium"
                                            icon="FeatherPlus"
                                            iconRight={
                                              showUpgradePlanLock &&
                                              !showExampleData
                                                ? 'FeatherLock'
                                                : null
                                            }
                                          >
                                            Instantiate Plan
                                          </Button>
                                        </LockingWrapper>
                                      </RouterLink>
                                    )}
                                    {template.is_regenerate_pending &&
                                      template.status === 'in-progress' && (
                                        <RegenerateTemplateDialog
                                          upgrade={template}
                                          onConfirm={mutate}
                                          account={account}
                                        />
                                      )}
                                    {template.status === 'in-progress' &&
                                      !showExampleData && (
                                        <MarkUpgradeTemplateAsApproved
                                          upgradeId={template.id}
                                          mutateTemplate={mutate}
                                        />
                                      )}
                                    <UpgradesMoreOptions
                                      upgrade={template}
                                      isTemplate={true}
                                      allowCancel={showCancel(template)}
                                      onConfirmEditName={async (newName) => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example templates cannot be updated',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradeTemplate(
                                              template.id,
                                              { name: newName },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-name-change',
                                              {
                                                template: template.id,
                                                name: newName,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully edited requested properties of the Upgrade Template',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'updating requested properties of the Upgrade Template',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          }
                                        }
                                      }}
                                      onConfirmCancel={async () => {
                                        if (showExampleData) {
                                          enqueueSnackbar(
                                            'Example templates cannot be canceled',
                                            {
                                              variant: 'warning',
                                              autoHideDuration:
                                                toastAutoHideDuration,
                                            },
                                          );
                                        } else {
                                          try {
                                            await updateUpgradeTemplate(
                                              template.id,
                                              { status: 'cancelled' },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${account.token}`,
                                                },
                                              },
                                            );
                                            logEvent(
                                              'upgrades-addons-plan-cancel',
                                              {
                                                templateId: template.id,
                                                name: template.name,
                                              },
                                            );
                                            mutate();
                                            enqueueSnackbar(
                                              'Successfully canceled Upgrade Template',
                                              {
                                                variant: 'success',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          } catch (err) {
                                            enqueueSnackbar(
                                              somethingWentWrong.replace(
                                                '<action>',
                                                'cancelling this Upgrade Template',
                                              ),
                                              {
                                                variant: 'error',
                                                autoHideDuration:
                                                  toastAutoHideDuration,
                                              },
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </div>
                                  <SubframeCore.Icon
                                    className={
                                      template.status === 'pending'
                                        ? 'text-body font-body text-subtext-color'
                                        : 'text-body font-body text-default-font'
                                    }
                                    name="FeatherChevronRight"
                                  />
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center gap-2">
                    <span className="text-body font-body text-default-font">
                      No Upgrade Templates requested.
                    </span>
                    <RouterLink to={`${addonUpgradeTemplatesBasePath}/new`}>
                      <LockingWrapper
                        feature={
                          ResolvedEntitlementFeaturesItem.request_upgrade_template
                        }
                        showLock={showTemplateLock}
                        showExampleData={showExampleData}
                        defaultAction={(event) => {
                          if (showExampleData) {
                            showCreateExampleTemplateToast(event);
                          }
                        }}
                        tooltipText={
                          showTemplateLock
                            ? ''
                            : 'Request Add-on Upgrade Template'
                        }
                      >
                        <Button
                          disabled={false}
                          size="medium"
                          icon="FeatherPlus"
                          iconRight={
                            showTemplateLock && !showExampleData
                              ? 'FeatherLock'
                              : null
                          }
                        >
                          Request Add-on Upgrade Template
                        </Button>
                      </LockingWrapper>
                    </RouterLink>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span className="text-body font-body text-default-font">
                  No clusters onboarded. Click here to onboard your first
                  cluster.
                </span>
                <RouterLink to={`${clustersBasePath}/new`}>
                  <Button
                    size="small"
                    icon="FeatherPlus"
                    onClick={() =>
                      logEvent('upgrades-templates-onboard-cluster-btn-click')
                    }
                  >
                    Add Cluster
                  </Button>
                </RouterLink>
              </div>
            )}
          </>
        )}
      </div>
    </Page>
  );
}

export default AddonUpgradeTemplatesLandingPage;
