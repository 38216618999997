import { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { Switch } from 'subframe/components/Switch';
import { InformationalHeader } from 'subframe/components/InformationalHeader';
import styles from './AcmeVersionRegisterAddOnsCertManagerV112InstanceDetails.module.scss';
import { useParams } from 'react-router';
import useUserAccountState from 'hooks/useUserAccountState';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAddonIdToDetailsMap } from 'hooks/useAddonIdToNameMap';
import {
  useGetAddonInstance,
  useListAddonInstanceResources,
} from 'api/frontend';
import {
  ADDONS,
  getAddonExpiryInWords,
  getAddonExpiryState,
  getHumanReadableImageTag,
} from 'src/data/version_register_addons';
import { CLUSTERS } from 'src/data/version_register_cp';
import { AddonInstanceResource, Cluster } from 'api/models';
import { RouterLink } from 'components/RouterLink';
import Page from 'components/Page';
import { AtomicTooltip } from 'components/design-system';
import { Alert, Breadcrumbs, SkeletonText } from 'subframe/index';
import BaseTable from 'components/design-system/Table/BaseTable';
import { ExpiryBadge } from './utils';
import { moreTooltipContent } from 'components/design-system/AtomicTooltip';
import { Select } from 'subframe/components/Select';
import { useListClustersInfinite } from 'api/frontend-infinite';
import { clusterFlattenPages } from 'utils/arrays';

export default function VersionRegisterAddonsViewInstance() {
  const { addonId, addonInstanceId } = useParams();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute('/orgs/:orgId/accounts/:accountId');
  const versionRegisterAddonBasePath = `${basePath}/artifact_register/addons`;
  const { logEvent } = AnalyticsEventLogger();
  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  const [filterNamespace, setFilterNamespace] = useState<string | undefined>(
    undefined,
  );
  const addonIdToDetailsMap = useAddonIdToDetailsMap({ token: account.token });
  const {
    data: getAddonInstancesResponse,
    isLoading: isLoadingAddonInstances,
  } = useGetAddonInstance(addonInstanceId || '', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
    swr: {
      enabled: !showExampleData,
    },
  });
  const { data: clustersList, isLoading: isLoadingClustersList } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: false,
      },
    );

  const listClustersResponse = clusterFlattenPages(clustersList || []) || [];

  const {
    data: listAddonInstanceResourcesResponse,
    error: listAddonInstanceResourcesError,
  } = useListAddonInstanceResources(addonInstanceId || '', {
    request: { headers: { Authorization: `Bearer ${account.token}` } },
    swr: {
      enabled: !showExampleData,
    },
  });

  const realClusterData = listClustersResponse
    ? listClustersResponse?.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];

  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  const addonInstance = showExampleData
    ? ADDONS.find((a) => a.id === addonInstanceId)
    : getAddonInstancesResponse;
  const version = getHumanReadableImageTag(
    addonInstance?.primary_component?.image,
  );
  const clusterData = showExampleData ? CLUSTERS : realClusterData;
  const clusterIdToDetailsMap: Map<string, Cluster> = new Map(
    clusterData.map((c) => [c.id, c]),
  );
  const addonName = addonIdToDetailsMap.get(addonId || '')?.name || addonId;
  const clusterName =
    clusterIdToDetailsMap.get(addonInstance?.cluster_id || '')?.name ||
    'Unknown';
  const expiryState = getAddonExpiryState(
    addonInstance === undefined ? [] : [addonInstance],
  );

  useEffect(() => {
    if (addonName && addonId && addonInstanceId) {
      logEvent('version-register-view-addon-instance', {
        addon_id: addonId,
        addon_name: addonName,
        addon_instance_id: addonInstanceId,
      });
    }
  }, []);

  let addonResources: AddonInstanceResource[] | undefined = showExampleData
    ? addonInstance?.resources || []
    : listAddonInstanceResourcesResponse?.data;
  const namespaces: Set<string> = new Set(
    addonResources?.map((resource) => resource.namespace) || [],
  );
  addonResources = addonResources?.filter(
    (resource) =>
      filterNamespace === undefined || resource.namespace === filterNamespace,
  );
  const addonResourcesByType = groupBy(
    addonResources || [],
    (resource) => resource.type,
  );

  const isDataLoading =
    addonResources == undefined || listAddonInstanceResourcesError == undefined;

  const addonNameToShow = (
    <>
      {addonIdToDetailsMap.size > 0 ? (
        addonName
      ) : (
        <SkeletonText className={'h-[20px] w-[50px]'} />
      )}{' '}
    </>
  );
  const clusterNameToShow = (
    <>
      {!isLoadingClustersList && clusterIdToDetailsMap?.size > 0 ? (
        clusterName
      ) : (
        <SkeletonText className={'h-[20px] w-[50px]'} />
      )}{' '}
    </>
  );
  return (
    <Page
      title={`Add-ons - ${addonName} ${
        addonInstance === undefined ? '' : addonInstance.version
      }`}
    >
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink to={versionRegisterAddonBasePath}>
            <Breadcrumbs.Item data-cy="breadcrumb-addon">
              Add-ons
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <RouterLink to={`${versionRegisterAddonBasePath}/${addonId}`}>
            <Breadcrumbs.Item data-cy="breadcrumb-addon-type">
              {addonNameToShow}
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <RouterLink
            to={`${versionRegisterAddonBasePath}/${addonId}/version/${encodeURIComponent(
              version,
            )}`}
          >
            <Breadcrumbs.Item active={false} data-cy="breadcrumb-version">
              {!isLoadingAddonInstances ? (
                version
              ) : (
                <SkeletonText className={'h-[20px] w-[50px]'} />
              )}{' '}
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>{clusterNameToShow}</Breadcrumbs.Item>
        </Breadcrumbs>
        <>
          <div className={styles['header']}>
            <div className={styles['stack-ae3760ec']}>
              <div className={styles['stack-d6ff3010']}>
                <span className="text-section-header font-section-header text-default-font">
                  <div className="flex gap-2">
                    {addonIdToDetailsMap.size > 0 ? (
                      addonName
                    ) : (
                      <SkeletonText className={'h-[38px] w-[50px]'} />
                    )}{' '}
                    {addonInstance?.version || ''} in{' '}
                    {!isLoadingClustersList &&
                    clusterIdToDetailsMap?.size > 0 ? (
                      clusterName
                    ) : (
                      <SkeletonText className={'h-[38px] w-[50px]'} />
                    )}{' '}
                  </div>
                </span>
                {addonInstance !== undefined &&
                  addonInstance.eol_date !== undefined &&
                  addonInstance.eol_date > 0 && (
                    <ExpiryBadge
                      expiryState={expiryState}
                      expiryInWords={getAddonExpiryInWords([addonInstance])}
                      eolSource={
                        addonIdToDetailsMap.get(addonId || '')?.eolSource
                      }
                    />
                  )}
              </div>
              <div className={styles['stack-cfd3a402']}>
                <span className={styles['text-f3b746ef']}>
                  <div className="flex gap-2">
                    Details about the running {addonNameToShow}
                    {addonInstance?.version || ''} in cluster{' '}
                    {clusterNameToShow}
                  </div>
                </span>
                <div className={styles['stack-68b0150a']}>
                  <span className={styles['text-6f4f6d44']}>
                    Show example data
                  </span>
                  <AtomicTooltip
                    tooltipContent={
                      showExampleData
                        ? 'Show results from your onboarded clusters'
                        : 'Show example data'
                    }
                  >
                    <Switch
                      checked={showExampleData}
                      onClick={() => setShowExampleData(!showExampleData)}
                    />
                  </AtomicTooltip>
                </div>
              </div>
            </div>
          </div>
          {showExampleData && (
            <Alert
              variant="warning"
              title="You are currently viewing example data"
              description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
            />
          )}

          <>
            <div className={styles['stack-4720eece']}>
              <InformationalHeader className="flex-[0_0_auto] h-auto w-auto">
                <div className={styles['stack-7451c0b2']}>
                  <span className={styles['version']}>Total</span>
                  <span
                    className={styles['bodyBoldText']}
                    data-cy="total-count"
                  >
                    {addonIdToDetailsMap.size > 0 && addonResources ? (
                      (addonResources || []).length
                    ) : (
                      <SkeletonText className="h-[20px] w-[50px]" />
                    )}
                  </span>
                </div>
                {Object.entries(addonResourcesByType).map(
                  ([type, resources]) => (
                    <div
                      className={styles['stack-4d37ec47']}
                      key={type}
                      data-cy="resources"
                    >
                      <span
                        className={styles['version-f243a5ee']}
                        data-cy="resource-type"
                      >
                        {type}
                      </span>
                      <span
                        className={styles['bodyBoldText']}
                        data-cy="resource-quantity"
                      >
                        {resources.length}
                      </span>
                    </div>
                  ),
                )}
                {addonIdToDetailsMap.get(addonId || '')?.eolSource !==
                  undefined && (
                  <InformationalHeader.Item
                    title="EOL Source"
                    iconName={null}
                    bodySlot={
                      <a
                        data-cy="eol-source"
                        style={{
                          color: 'var(--subtext-color)',
                          font: 'var(--body)',
                        }}
                        href={addonIdToDetailsMap.get(addonId || '')?.eolSource}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more
                      </a>
                    }
                  />
                )}
              </InformationalHeader>
              <div className={styles['stack-1aff8605']}>
                <Select
                  icon={null}
                  className="w-auto max-w-[400px]"
                  placeholder={filterNamespace || 'All Namespaces'}
                  onValueChange={(value) => {
                    if (value === 'all') {
                      setFilterNamespace(undefined);
                    } else {
                      setFilterNamespace(value);
                    }
                  }}
                >
                  <Select.Item key="all" value="all">
                    All Namespaces
                  </Select.Item>
                  {Array.from(namespaces).map((namespace) => (
                    <Select.Item key={namespace} value={namespace}>
                      {namespace}
                    </Select.Item>
                  ))}
                </Select>
              </div>
            </div>
            {!isDataLoading ? (
              <BaseTable
                settings={{
                  localStorageKey:
                    'artifact_register_addons_view_instance_table',
                  dataCyPrefix: 'vr-addon-view-instance',
                }}
                data={[
                  <SkeletonText key="1" className={'h-[30px] w-[50px]'} />,
                ]}
                columns={[
                  {
                    id: 'cluster',
                    title: 'Cluster',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'environment',
                    title: 'Environment',
                    titleStyle: { width: '30%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                  {
                    id: 'provider',
                    title: 'Provider / Region',
                    titleStyle: { width: '30%' },
                    cellType: 'cell',
                    render: (skeletonLoading) => {
                      return skeletonLoading;
                    },
                  },
                ]}
                actions={[]}
              />
            ) : (
              <BaseTable
                settings={{
                  localStorageKey:
                    'artifact_register_addons_view_instance_table',
                  dataCyPrefix: 'vr-addon-view-instance',
                }}
                data={
                  addonResources?.sort((a, b) => (a.name > b.name ? 1 : -1)) ||
                  []
                }
                columns={[
                  {
                    id: 'namespace',
                    title: 'Namespace',
                    cellType: 'text-cell',
                    cellProperties: {
                      primary: true,
                    },
                    render: (row: AddonInstanceResource) => {
                      return row.namespace;
                    },
                  },
                  {
                    id: 'type',
                    title: 'Kind',
                    cellType: 'text-cell',
                    render: (row: AddonInstanceResource) => {
                      return row.type;
                    },
                  },
                  {
                    id: 'name',
                    title: 'Name',
                    cellType: 'text-cell',
                    render: (row: AddonInstanceResource) => {
                      return row.name;
                    },
                  },
                  {
                    id: 'images',
                    title: 'Running Images',
                    cellType: 'cell',
                    cellStyle: { maxWidth: '500px' },
                    render: (row: AddonInstanceResource) => {
                      if (row.images === undefined) {
                        return (
                          <span
                            style={{
                              color: 'var(--neutral-500)',
                              font: 'var(--body)',
                            }}
                          >
                            n/a
                          </span>
                        );
                      }
                      {
                        let images = row.images.map(
                          (i) =>
                            `${i.registry_url}${
                              i.tag === undefined ? '' : ':' + i.tag
                            }${i.digest === undefined ? '' : '@' + i.digest}`,
                        );
                        let widthLimit = 2500;
                        return (
                          <>
                            <AtomicTooltip
                              tooltipContent={
                                window.innerWidth > widthLimit
                                  ? images.slice(0, 2).join(',')
                                  : images[0]
                              }
                            >
                              <span
                                style={{
                                  maxWidth:
                                    images.length >
                                    (window.innerWidth > widthLimit ? 2 : 1)
                                      ? '90%'
                                      : '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  color: 'var(--neutral-500)',
                                  font: 'var(--body)',
                                }}
                              >
                                {window.innerWidth > widthLimit
                                  ? images.slice(0, 2).join(', ')
                                  : images[0]}
                              </span>
                            </AtomicTooltip>
                            {images.length >
                              (window.innerWidth > widthLimit ? 2 : 1) && (
                              <AtomicTooltip
                                tooltipContent={moreTooltipContent(images, 1)}
                              >
                                <span
                                  style={{
                                    color: 'var(--subtext-color)',
                                    font: 'var(--body)',
                                  }}
                                >
                                  +{Math.max(0, row.images.length - 1)} more
                                </span>
                              </AtomicTooltip>
                            )}
                          </>
                        );
                      }
                    },
                  },
                ]}
                actions={[]}
              />
            )}
          </>
        </>
      </div>
    </Page>
  );
}
