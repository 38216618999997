import { AtomicTooltip } from 'components/design-system';
import { IconButton } from 'subframe/index';

export const DeleteCommentButton = ({
  isDeleting,
  onClick,
  hideButton,
}: {
  isDeleting: boolean;
  onClick: () => void;
  hideButton: boolean;
}) => {
  return (
    <AtomicTooltip tooltipContent="Delete the last comment">
      <IconButton
        className={hideButton ? 'hidden' : ''}
        icon="FeatherTrash"
        loading={isDeleting}
        disabled={isDeleting}
        onClick={onClick}
      />
    </AtomicTooltip>
  );
};
