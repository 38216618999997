import { AddonInstance, ImageName } from 'api/models';
import {
  addDays,
  differenceInDays,
  formatDistanceToNowStrict,
  format,
  isBefore,
  subDays,
  subYears,
} from 'date-fns';
import { CLUSTERS } from './version_register_cp';
import { AccountAddon } from 'api/models';

export function getAddonExpiryInWords(
  instances: AddonInstance[] | AccountAddon[],
): string | undefined {
  // landing page gives all instances for a single addon_id
  // view_addons page gives all instances for a given primary comp image tag

  const expiryDates = instances
    .filter((c) => getEOLDate(c) !== undefined)
    .map((c) => getEOLDate(c) || 0);
  if (expiryDates.length === 0) {
    return undefined;
  }

  const earliestExpiry: number = Math.min(...expiryDates);
  if (earliestExpiry === 0) {
    return undefined;
  } else if (earliestExpiry === 1) {
    return 'more than a year ago';
  } else if (earliestExpiry === 2) {
    return 'Date not provided by maintainers';
  } else {
    const expiryDate = new Date(Math.min(...expiryDates) * 1000);
    if (isBefore(expiryDate, subYears(new Date(), 1))) {
      return 'more than a year ago';
    } else {
      return (
        formatDistanceToNowStrict(expiryDate, { addSuffix: true }) +
        format(expiryDate, ' (LLL yyyy)')
      );
    }
  }
}

function getEOLDate(
  instance: AddonInstance | AccountAddon,
): number | undefined {
  if ('eol_date' in instance) {
    return instance.eol_date;
  } else if ('earliest_eol_date' in instance) {
    return instance.earliest_eol_date;
  } else {
    return undefined;
  }
}

export function getEarliestAddonExpiryInDays(
  instances: AddonInstance[] | AccountAddon[],
): number | undefined {
  const expiryDates = instances
    .map((c) => getEOLDate(c) || 0)
    .filter((d) => d > 0);
  if (expiryDates.length === 0) {
    return undefined;
  }

  return differenceInDays(
    new Date(Math.min(...expiryDates) * 1000),
    new Date(),
  );
}

export function getAddonExpiryState(
  instances: AddonInstance[] | AccountAddon[],
): 'default' | 'warning' | 'error' {
  const earliestExpiry = getEarliestAddonExpiryInDays(instances);
  if (earliestExpiry === undefined || earliestExpiry > 90) {
    return 'default';
  } else if (earliestExpiry >= 0) {
    return 'warning';
  } else {
    return 'error';
  }
}

export function getHumanReadableImageTag(
  imageName: ImageName | undefined,
): string {
  if (imageName?.tag !== undefined) {
    return imageName.tag;
  } else if (imageName?.digest !== undefined) {
    return `${imageName.digest.slice(0, 7 + 6)}`;
  } else {
    return 'Unknown';
  }
}

// Takes an array of cluster IDs and an array of addon instances and assigns
// each addon instance a cluster ID. If an addon instance already has a cluster
// ID, it is left unchanged.
function distributeClusterIdsToAddonInstances(
  clusterIds: string[],
  addons: AddonInstance[],
): void {
  addons.forEach((addon, index) => {
    addon.cluster_id =
      addon.cluster_id === ''
        ? clusterIds[index % clusterIds.length]
        : addon.cluster_id;
  });
}

// https://github.com/chkk-io/sprints/issues/2959
// We keep the eol dates dynamic to always show both
// yellow and red clocks on the dashboard
export const ADDONS: AddonInstance[] = [
  {
    id: 'id-addon_coredns',
    addon_id: 'addon_b692878c-4298-4f61-8100-df19363b0ed6',
    cluster_id: '',
    version: '1.7.0-eksbuild.1',
    primary_component: {
      image: {
        registry_url:
          '602401143452.dkr.ecr.us-west-2.amazonaws.com/eks/coredns',
        tag: '1.7.0-eksbuild.1',
      },
    },
    resources: [
      {
        name: 'coredns',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              '602401143452.dkr.ecr.us-west-2.amazonaws.com/eks/coredns',
            tag: '1.7.0-eksbuild.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_awspcicni',
    addon_id: 'addon_404d077e-3259-417b-9ce8-04aa1459fa16',
    cluster_id: '',
    version: '1.7.5-eksbuild.1',
    primary_component: {
      image: { registry_url: '', tag: '1.7.5-eksbuild.1' },
    },
    resources: [
      {
        name: 'aws-node',
        type: 'DaemonSet',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              '602401143452.dkr.ecr.us-west-2.amazonaws.com/amazon-k8s-cni',
            tag: '1.7.5-eksbuild.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_nginxingress',
    addon_id: 'addon_5b72ff12-b76a-46fe-b7c6-e6a8f5982c51',
    cluster_id: '',
    version: 'latest',
    primary_component: {
      image: { registry_url: '', tag: 'latest' },
    },
    resources: [
      {
        name: 'ingress-nginx-controller',
        type: 'Deployment',
        namespace: 'ingress-nginx',
        images: [
          {
            registry_url: 'registry.k8s.io/ingress-nginx/controller',
            tag: 'latest',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_awsalb',
    addon_id: 'addon_29177308-3960-4628-9e5d-8c25a8c24f59',
    cluster_id: '',
    version: 'latest',
    primary_component: {
      image: { registry_url: '', tag: 'latest' },
    },
    resources: [
      {
        name: 'aws-load-balancer-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'public.ecr.aws/eks/aws-load-balancer-controller',
            tag: 'latest',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_kubeproxy-1',
    addon_id: 'addon_143085db-4ed1-46da-ad99-0e79868d44ca',
    cluster_id: '',
    version: '1.18.8-eksbuild.1',
    primary_component: {
      image: { registry_url: '', tag: '1.18.8-eksbuild.1' },
    },
    resources: [
      {
        name: 'kube-proxy',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              '602401143452.dkr.ecr.us-west-2.amazonaws.com/k8s/kube-proxy',
            tag: '1.18.8-eksbuild.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: subDays(new Date(), 2).getTime() / 1000,
  },
  {
    id: 'id-addon_kubeproxy-2',
    addon_id: 'addon_143085db-4ed1-46da-ad99-0e79868d44ca',
    cluster_id: '',
    version: '1.24.10-eksbuild.1',
    primary_component: {
      image: { registry_url: '', tag: '1.24.10-eksbuild.1' },
    },
    resources: [
      {
        name: 'kube-proxy',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              '602401143452.dkr.ecr.us-west-2.amazonaws.com/k8s/kube-proxy',
            tag: '1.24.10-eksbuild.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 30).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-1',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.12',
    primary_component: {
      image: { registry_url: '', tag: '1.12' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.12',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-2',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.12',
    primary_component: {
      image: { registry_url: '', tag: '1.12' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.12',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-3',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.12',
    primary_component: {
      image: { registry_url: '', tag: '1.12' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.12',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-4',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.12',
    primary_component: {
      image: { registry_url: '', tag: '1.12' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.12',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-5',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.5.4',
    primary_component: {
      image: { registry_url: '', tag: '1.5.4' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.5.4',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: subDays(new Date(), 370).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-6',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.3.2',
    primary_component: {
      image: { registry_url: '', tag: '1.3.2' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.3.2',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: subDays(new Date(), 370).getTime() / 1000,
  },
  {
    id: 'id-addon_certmanager-7',
    addon_id: 'addon_d7191da5-c5f3-45f7-a620-38d434dd5096',
    cluster_id: '',
    version: '1.3.2',
    primary_component: {
      image: { registry_url: '', tag: '1.3.2' },
    },
    resources: [
      {
        name: 'cert-manager',
        type: 'Deployment',
        namespace: 'cert-manager',
        images: [
          {
            registry_url: 'quay.io/jetstack/cert-manager-controller',
            tag: 'v1.3.2',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 60).getTime() / 1000,
    eol_date: subDays(new Date(), 370).getTime() / 1000,
  },
  {
    id: 'id-addon_argocd_1',
    addon_id: 'addon_30e39ed1-5e82-4806-af53-92866f4b25e0',
    cluster_id: '',
    version: '2.5.1',
    primary_component: {
      image: { registry_url: 'quay.io/argoproj/argocd', tag: '2.5.1' },
    },
    resources: [
      {
        name: 'argocd',
        type: 'Deployment',
        namespace: 'argocd',
        images: [
          {
            registry_url: 'quay.io/argoproj/argocd',
            tag: '2.5.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 370).getTime() / 1000,
  },
  {
    id: 'id-addon_argocd_2',
    addon_id: 'addon_30e39ed1-5e82-4806-af53-92866f4b25e0',
    cluster_id: '',
    version: '2.4.1',
    primary_component: {
      image: { registry_url: 'quay.io/argoproj/argocd', tag: '2.4.1' },
    },
    resources: [
      {
        name: 'argocd',
        type: 'Deployment',
        namespace: 'argocd',
        images: [
          {
            registry_url: 'quay.io/argoproj/argocd',
            tag: '2.4.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 7).getTime() / 1000,
  },
  {
    id: 'id-addon_kube-rbac-proxy',
    addon_id: 'addon_edc1e7ab-53fa-4ea1-a78d-0b8aa9e36dce',
    cluster_id: '',
    version: 'v0.10.0',
    primary_component: {
      image: { registry_url: '', tag: 'v0.10.0' },
    },
    resources: [
      {
        name: 'controller-manager',
        type: 'Deployment',
        namespace: 'metrics',
        images: [
          {
            registry_url: 'quay.io/brancz/kube-rbac-proxy',
            tag: 'v0.10.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-addon_cillium',
    addon_id: 'addon_fdd78e80-073e-445f-96cd-bdd4fa94a6f3',
    cluster_id: '',
    version: 'v1.13.6',
    primary_component: {
      image: { registry_url: 'quay.io/cilium/cilium', tag: 'v1.13.6' },
    },
    resources: [
      {
        name: 'cilium-operator',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'quay.io/cilium/operator-aws',
            tag: 'v1.13.6',
          },
        ],
      },
      {
        name: 'clustermesh-apiserver',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'quay.io/cilium/clustermesh-apiserver',
            tag: 'v1.13.6',
          },
        ],
      },
      {
        name: 'cilium',
        type: 'DaemonSet',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'quay.io/cilium/cilium',
            tag: 'v1.13.6',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 50).getTime() / 1000,
  },
  {
    id: 'id-aws-ebs-csi-driver',
    addon_id: 'addon_517bf052-e2f1-4168-8c14-e1ff06b231e5',
    cluster_id: '',
    version: 'v1.14.1',
    primary_component: {
      image: {
        registry_url: 'public.ecr.aws/ebs-csi-driver/aws-ebs-csi-driver',
        tag: 'v1.14.1',
      },
    },
    resources: [
      {
        name: 'ebs-csi-node',
        type: 'DaemonSet',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'public.ecr.aws/ebs-csi-driver/aws-ebs-csi-driver',
            tag: 'v1.14.1',
          },
        ],
      },
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url: 'public.ecr.aws/ebs-csi-driver/aws-ebs-csi-driver',
            tag: 'v1.14.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 50).getTime() / 1000,
  },
  {
    id: 'id-keda',
    addon_id: 'addon_304813ed-5423-4a5e-8783-49c7af10572e',
    cluster_id: '',
    version: '2.9.3',
    primary_component: {
      image: {
        registry_url: 'ghcr.io/kedacore/keda-metrics-apiserver',
        tag: '2.9.3',
      },
    },
    resources: [
      {
        name: 'keda-operator',
        type: 'Deployment',
        namespace: 'keda-system',
        images: [
          {
            registry_url: 'ghcr.io/kedacore/keda',
            tag: '2.9.3',
          },
        ],
      },
      {
        name: 'keda-operator-metrics-apiserver',
        type: 'Deployment',
        namespace: 'keda-system',
        images: [
          {
            registry_url: 'ghcr.io/kedacore/keda-metrics-apiserver',
            tag: '2.9.3',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 80).getTime() / 1000,
  },
  {
    id: 'id-external-provisioner',
    addon_id: 'addon_85d81bd0-267a-40ec-8313-e9cbd19d5f9c',
    cluster_id: '',
    version: 'v3.1.0-eks-1-20-15',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
        tag: 'v3.1.0-eks-1-20-15',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
            tag: 'v3.1.0-eks-1-20-15',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-external-snapshotter',
    addon_id: 'addon_ea1b144f-2aba-45a0-ac04-2ac9fbdaeef3',
    cluster_id: '',
    version: 'v6.0.1-eks-1-20-17',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/external-snapshotter/csi-snapshotter',
        tag: 'v6.0.1-eks-1-20-17',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/external-snapshotter/csi-snapshotter',
            tag: 'v6.0.1-eks-1-20-17',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-node-registrar',
    addon_id: 'addon_948277b3-eb2a-412f-90e6-8b7dad26f441',
    cluster_id: '',
    version: 'v2.5.1-eks-1-20-17',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/node-driver-registrar',
        tag: 'v2.5.1-eks-1-20-17',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/node-driver-registrar',
            tag: 'v2.5.1-eks-1-20-17',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-external-attacher',
    addon_id: 'addon_8b040e4e-4b08-4d22-9f16-97193fdfd580',
    cluster_id: '',
    version: 'v3.4.0-eks-1-20-15',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/external-attacher',
        tag: 'v3.4.0-eks-1-20-15',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/external-attacher',
            tag: 'v3.4.0-eks-1-20-15',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-vault-secrets-operator',
    addon_id: 'addon_e79277b6-3549-428f-b3c8-08cabe46c878',
    cluster_id: '',
    version: '0.2.0',
    primary_component: {
      image: {
        registry_url: 'hashicorp/vault-secrets-operator',
        tag: '0.2.0',
      },
    },
    resources: [
      {
        name: 'vault-secrets-operator-controller-manager',
        type: 'Deployment',
        namespace: 'vault-secrets-operator',
        images: [
          {
            registry_url: 'hashicorp/vault-secrets-operator',
            tag: '0.2.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 45).getTime() / 1000,
  },
  {
    id: 'id-vault-k8s',
    addon_id: 'addon_71f370fe-dc07-4f9b-a5e8-937efbefe637',
    cluster_id: '',
    version: '0.17.0',
    primary_component: {
      image: {
        registry_url: 'hashicorp/vault-k8s',
        tag: '0.17.0',
      },
    },
    resources: [
      {
        name: 'vault-agent-injector',
        type: 'Deployment',
        namespace: 'vault-agent-injector',
        images: [
          {
            registry_url: 'hashicorp/vault-k8s',
            tag: '0.17.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 120).getTime() / 1000,
  },
  {
    id: 'id-envoy',
    addon_id: 'addon_bbc928bc-9c71-4a8e-bb9c-f4ca4dd2d62c',
    cluster_id: '',
    version: 'v1.21-latest',
    primary_component: {
      image: {
        registry_url: 'envoyproxy/envoy',
        tag: 'v1.21-latest',
      },
    },
    resources: [
      {
        name: 'reverse-proxy',
        type: 'Deployment',
        namespace: 'envoy-oauth-proxy',
        images: [
          {
            registry_url: 'envoyproxy/envoy',
            tag: 'v1.21-latest',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: subDays(new Date(), 157).getTime() / 1000,
  },
  {
    id: 'id-grafana',
    addon_id: 'addon_89549157-9fbe-4297-90f2-11122c700aee',
    cluster_id: '',
    version: '8.2.1',
    primary_component: {
      image: {
        registry_url: 'grafana/grafana',
        tag: '8.2.1',
      },
    },
    resources: [
      {
        name: 'grafana',
        type: 'Deployment',
        namespace: 'metrics',
        images: [
          {
            registry_url: 'grafana/grafana',
            tag: '8.2.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-external-provisioner',
    addon_id: 'addon_85d81bd0-267a-40ec-8313-e9cbd19d5f9c',
    cluster_id: '',
    version: 'v3.1.0-eks-1-20-15',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
        tag: 'v3.1.0-eks-1-20-15',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
            tag: 'v3.1.0-eks-1-20-15',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-karpenter',
    addon_id: 'addon_fef30be6-1088-4545-93c3-5804b112dd84',
    cluster_id: '',
    version: 'v0.23.0',
    primary_component: {
      image: {
        registry_url: 'public.ecr.aws/karpenter/controller',
        tag: 'v0.23.0',
      },
    },
    resources: [
      {
        name: 'karpenter',
        type: 'Deployment',
        namespace: 'karpenter',
        images: [
          {
            registry_url: 'public.ecr.aws/karpenter/controller',
            tag: 'v0.23.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 24).getTime() / 1000,
  },
  {
    id: 'id-external-resizer',
    addon_id: 'addon_2b213ff5-ee72-4b25-818d-b4ba8f1edeb0',
    cluster_id: '',
    version: 'v3.1.0-eks-1-20-15',
    primary_component: {
      image: {
        registry_url:
          'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
        tag: 'v3.1.0-eks-1-20-15',
      },
    },
    resources: [
      {
        name: 'ebs-csi-controller',
        type: 'Deployment',
        namespace: 'kube-system',
        images: [
          {
            registry_url:
              'public.ecr.aws/eks-distro/kubernetes-csi/external-provisioner',
            tag: 'v3.1.0-eks-1-20-15',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-fluent-bit',
    addon_id: 'addon_6412368a-680d-47f7-82a0-0930561ddd36',
    cluster_id: '',
    version: '2.31.12.20230911',
    primary_component: {
      image: {
        registry_url: 'public.ecr.aws/aws-observability/aws-for-fluent-bit',
        tag: '2.31.12.20230911',
      },
    },
    resources: [
      {
        name: 'fluent-bit',
        type: 'DaemonSet',
        namespace: 'logging',
        images: [
          {
            registry_url: 'public.ecr.aws/aws-observability/aws-for-fluent-bit',
            tag: '2.31.12.20230911',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: subDays(new Date(), 20).getTime() / 1000,
  },
  {
    id: 'id-alertmanager_1',
    addon_id: 'addon_d6e318d9-5802-46a4-a497-a6ffa7f8c57f',
    cluster_id: '',
    version: 'v0.26.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/alertmanager',
        tag: 'v0.26.0',
      },
    },
    resources: [
      {
        name: 'prometheus-alertmanager',
        type: 'StatefulSet',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/alertmanager',
            tag: 'v0.26.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-alertmanager_2',
    addon_id: 'addon_d6e318d9-5802-46a4-a497-a6ffa7f8c57f',
    cluster_id: '',
    version: 'v0.26.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/alertmanager',
        tag: 'v0.26.0',
      },
    },
    resources: [
      {
        name: 'prometheus-alertmanager',
        type: 'StatefulSet',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/alertmanager',
            tag: 'v0.26.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: subDays(new Date(), 280).getTime() / 1000,
  },
  {
    id: 'id-configmap-reloader_1',
    addon_id: 'addon_07ca056a-e803-465a-873b-64e708d4b311',
    cluster_id: '',
    version: 'v0.67.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus-operator/prometheus-config-reloader',
        tag: 'v0.67.0',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url:
              'quay.io/prometheus-operator/prometheus-config-reloader',
            tag: 'v0.67.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-configmap-reloader_2',
    addon_id: 'addon_07ca056a-e803-465a-873b-64e708d4b311',
    cluster_id: '',
    version: 'v0.67.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus-operator/prometheus-config-reloader',
        tag: 'v0.67.0',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url:
              'quay.io/prometheus-operator/prometheus-config-reloader',
            tag: 'v0.67.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-fluxcd',
    addon_id: 'addon_66773515-dfff-4bb6-884c-e52557eaa4c2',
    cluster_id: '',
    version: 'v1.0.1',
    primary_component: {
      image: {
        registry_url: 'ghcr.io/fluxcd/source-controller',
        tag: 'v1.0.1',
      },
    },
    resources: [
      {
        name: 'source-controller',
        type: 'Deployment',
        namespace: 'flux-system',
        images: [
          {
            registry_url: 'ghcr.io/fluxcd/source-controller',
            tag: 'v1.0.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
  },
  {
    id: 'id-node-exporter_1',
    addon_id: 'addon_270cf695-afe4-47f4-9abb-00fc5ab7accc',
    cluster_id: '',
    version: 'v1.6.1',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/node-exporter',
        tag: 'v1.6.1',
      },
    },
    resources: [
      {
        name: 'prometheus-prometheus-node-exporter',
        type: 'DaemonSet',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/node-exporter',
            tag: 'v1.6.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-node-exporter_2',
    addon_id: 'addon_270cf695-afe4-47f4-9abb-00fc5ab7accc',
    cluster_id: '',
    version: 'v1.6.1',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/node-exporter',
        tag: 'v1.6.1',
      },
    },
    resources: [
      {
        name: 'prometheus-prometheus-node-exporter',
        type: 'DaemonSet',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/node-exporter',
            tag: 'v1.6.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-kube-state-metrics_1',
    addon_id: 'addon_f8dbd4eb-4dc8-44f1-910b-86d6c64ff46b',
    cluster_id: '',
    version: 'v2.10.0',
    primary_component: {
      image: {
        registry_url: 'registry.k8s.io/kube-state-metrics/kube-state-metrics',
        tag: 'v2.10.0',
      },
    },
    resources: [
      {
        name: 'prometheus-kube-state-metrics',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url:
              'registry.k8s.io/kube-state-metrics/kube-state-metrics',
            tag: 'v2.10.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-kube-state-metrics_2',
    addon_id: 'addon_f8dbd4eb-4dc8-44f1-910b-86d6c64ff46b',
    cluster_id: '',
    version: 'v2.10.0',
    primary_component: {
      image: {
        registry_url: 'registry.k8s.io/kube-state-metrics/kube-state-metrics',
        tag: 'v2.10.0',
      },
    },
    resources: [
      {
        name: 'prometheus-kube-state-metrics',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url:
              'registry.k8s.io/kube-state-metrics/kube-state-metrics',
            tag: 'v2.10.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-prometheus_1',
    addon_id: 'addon_7f1e198a-c2e0-446b-8567-f7154dfb38de',
    cluster_id: '',
    version: 'v2.47.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/prometheus',
        tag: 'v2.47.0',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/prometheus',
            tag: 'v2.47.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    id: 'id-prometheus_2',
    addon_id: 'addon_7f1e198a-c2e0-446b-8567-f7154dfb38de',
    cluster_id: '',
    version: 'v2.47.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus/prometheus',
        tag: 'v2.47.0',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'quay.io/prometheus/prometheus',
            tag: 'v2.47.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: subDays(new Date(), 221).getTime() / 1000,
  },
  {
    //! used for example plan
    id: 'addinst_54d5a2db-144e-40eb-a828-5757dddec241',
    addon_id: 'addon_cbdaa82d-3360-4bc7-a2b6-0978d11e96d4',
    cluster_id: 'id-eks-127-usw2-prod',
    version: '1.21.5',
    primary_component: {
      image: {
        registry_url: 'docker.io/istio/pilot',
        tag: '1.21.5',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus',
        images: [
          {
            registry_url: 'docker.io/istio/pilot',
            tag: '1.21.5',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: subDays(new Date(), 221).getTime() / 1000,
  },
  {
    //! used for example plan
    id: 'id-prometheus-operator-upgrade-1',
    addon_id: 'addon_07ca056a-e803-465a-873b-64e708d4b311',
    cluster_id: 'id-gke-127-usw4-dev',
    version: 'v0.63.0',
    primary_component: {
      image: {
        registry_url: 'quay.io/prometheus-operator/prometheus-operator',
        tag: 'v0.63.0',
      },
    },
    resources: [
      {
        name: 'prometheus-server',
        type: 'Deployment',
        namespace: 'prometheus-operator',
        images: [
          {
            registry_url: 'quay.io/prometheus-operator/prometheus-operator',
            tag: 'v0.63.0',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    //! used for example plan
    id: 'id-grafana-loki-upgrade-1',
    addon_id: 'addon_1a2b3c4d-5e6f-7g8h-80b5-9i10j11k12l1',
    cluster_id: 'id-eks-125-euw1-staging',
    version: 'v2.6.1',
    primary_component: {
      image: {
        registry_url: 'docker.io/bitnami/grafana-loki',
        tag: 'v2.6.1',
      },
    },
    resources: [
      {
        name: 'grafana-loki',
        type: 'Deployment',
        namespace: 'grafana',
        images: [
          {
            registry_url: 'docker.io/bitnami/grafana-loki',
            tag: 'v2.6.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 221).getTime() / 1000,
  },
  {
    //! used for example plan
    id: 'id-argocd_upgrade-1',
    addon_id: 'addon_30e39ed1-5e82-4806-af53-92866f4b25e0',
    cluster_id: 'id-eks-127-use1-prod',
    version: '2.5.1',
    primary_component: {
      image: { registry_url: 'quay.io/argoproj/argocd', tag: '2.5.1' },
    },
    resources: [
      {
        name: 'argocd',
        type: 'Deployment',
        namespace: 'argocd',
        images: [
          {
            registry_url: 'quay.io/argoproj/argocd',
            tag: '2.5.1',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 370).getTime() / 1000,
  },
  {
    //! used for example plan
    id: 'id-nginx-oss-upgrade-1',
    addon_id: 'addon_1e79922d-eb21-4cf6-ac64-08b8eb7ef24c',
    cluster_id: 'id-eks-127-use1-prod',
    version: '1.14.2',
    primary_component: {
      image: { registry_url: 'nginx', tag: '1.14.2' },
    },
    resources: [
      {
        name: 'nginx',
        type: 'Deployment',
        namespace: 'nginx',
        images: [
          {
            registry_url: 'nginx',
            tag: '1.14.2',
          },
        ],
      },
    ],
    // LastUpgraded: subDays(new Date(), 300).getTime() / 1000,
    eol_date: addDays(new Date(), 370).getTime() / 1000,
  },
];

const clusterIds = CLUSTERS.map((c) => c.id);
distributeClusterIdsToAddonInstances(clusterIds, ADDONS);
