'use client';
/* Release: 63880259 (Latest – unreleased) */
import { Button, Dialog, IconButton } from 'subframe/index';
import styles from './FeatureAccessDialog.module.scss';
import { SetStateFunction } from 'models/react';
import { RouterLink } from 'components/RouterLink';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { GuidedPocContactUsButton } from 'components/GuidedPocContactUsButton';
import { ResolvedEntitlementFeaturesItem, SubscriptionPlan } from 'api/models';
import { ProductPlanContactUsButton } from 'components/ProductPlanContactUsButton';
import useUserAccountState from 'hooks/useUserAccountState';

function FeatureAccessDialog({
  open,
  setOpen,
  currentPlan,
  feature,
  upgradePlanType,
}: {
  open: boolean;
  setOpen: SetStateFunction<boolean>;
  currentPlan: SubscriptionPlan;
  feature: ResolvedEntitlementFeaturesItem;
  upgradePlanType?: 'cluster' | 'addon';
}) {
  const { logEvent } = AnalyticsEventLogger();
  const { currentOrganization } = useUserAccountState();

  const getDialogContent = () => {
    switch (feature) {
      case ResolvedEntitlementFeaturesItem.request_upgrade_plan:
        return {
          heading:
            upgradePlanType === 'cluster'
              ? 'Instantiate Upgrade Plans'
              : 'Create Upgrade Plans',
          body:
            upgradePlanType === 'cluster'
              ? 'You have two options to Instantiate Upgrade Plans:'
              : 'You have two options to Create Upgrade Plans:',
          option2Text:
            currentPlan && currentPlan === 'Starter'
              ? 'Contact us to purchase additional Upgrade Plans.'
              : 'Contact us to start a Guided POC Evaluation, which includes Upgrade Plans for a limited time.',
        };

      case ResolvedEntitlementFeaturesItem.request_upgrade_template:
        return {
          heading: 'Create Upgrade Templates',
          body: 'You have two options to Create Upgrade Templates:',
          option2Text:
            currentPlan && currentPlan === 'Starter'
              ? 'Contact us to purchase additional Upgrade Templates.'
              : 'Contact us to start a Guided POC Evaluation, which includes Upgrade Templates for a limited time.',
        };
      default:
        return {
          heading: '',
          body: '',
          option2Text: '',
        };
    }
  };

  const dialogContent = getDialogContent();
  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <Dialog.Content>
        <div
          className={styles['stack-c03cc443']}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <div className={styles['stack-fd72537a']}>
            <div className={styles['stack-52ea7533']}>
              <span className={styles['text-d094b31a']}>
                {dialogContent.heading}{' '}
              </span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setOpen(false);
                  logEvent('close-upgrade-subscription-dialog');
                }}
              />
            </div>
            {/* subheader for dialog, might be needed in future */}
            {/* <span className={styles['text-ad4b1902']}>
              We are here to help you meet the requirements of your Kubernetes
              infrastructure
            </span> */}
          </div>
          <div className={styles['stack-5e104439']}>
            <span className={styles['bodyText']}>{dialogContent.body} </span>
            <div className={styles['options']}>
              <div className={styles['stack-6e0de86e']}>
                <span
                  className={styles['text-c7c7918d']}
                  style={{ textDecoration: 'underline' }}
                >
                  Option 1:
                </span>
                <span className={styles['bodyText']}>
                  Upgrade to Business Plan.
                </span>
              </div>
              <div className={styles['stack-5f1bc5e7']}>
                <span
                  className={styles['text-f9108dc1']}
                  style={{ textDecoration: 'underline' }}
                >
                  Option 2:
                </span>

                <span className={styles['bodyText']}>
                  {dialogContent?.option2Text}
                </span>
              </div>
            </div>
          </div>
          <div className={styles['stack']}>
            {currentPlan && currentPlan === 'Starter' ? (
              <ProductPlanContactUsButton
                variant="brand-secondary"
                size="large"
                handleClose={() => {
                  setOpen(false);
                }}
                view={'Upgrades'}
                plan={'AdditionalQuota'}
              />
            ) : (
              <GuidedPocContactUsButton
                variant="brand-secondary"
                size="large"
                handleClose={() => {
                  setOpen(false);
                }}
              />
            )}

            <RouterLink
              to={useAccountIdRoute('/orgs/:orgId/subscriptions?view=billing')}
            >
              <Button
                size="large"
                disabled={false}
                variant="brand-primary"
                icon="FeatherArrowBigUp"
                loading={false}
                onClick={() => {
                  logEvent('upgrade-to-business-plan', {
                    org_id: currentOrganization.id,
                  });
                }}
              >
                Upgrade to Business
              </Button>
            </RouterLink>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default FeatureAccessDialog;
