import { useState } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Collapse, IconButton, List, ListSubheader } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';
import Iconify from 'components/Iconify';
import { Stack } from 'components/utils';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  let defaultOpenState: { [name: string]: boolean } = {};
  navConfig.forEach((val) => (defaultOpenState[val.subheader] = true));
  const [open, setOpen] = useState(defaultOpenState);
  const theme = useTheme();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={2}>
                {group.subheader}
              </Stack>

              <IconButton
                onClick={() =>
                  setOpen({
                    ...open,
                    [group.subheader]: !open[group.subheader],
                  })
                }
                sx={{
                  backgroundColor: theme.palette.grey[900],
                  height: 16,
                  width: 16,
                  padding: 0,
                }}
              >
                <Iconify
                  icon={
                    open[group.subheader]
                      ? 'dashicons:arrow-down-alt2'
                      : 'dashicons:arrow-right-alt2'
                  }
                />
              </IconButton>
            </Box>
          </ListSubheaderStyle>

          {group.items.map((list, index) => (
            <Collapse
              key={`${group.subheader} + ${index}`}
              in={open[group.subheader]}
              timeout="auto"
              unmountOnExit
            >
              <NavListRoot list={list} isCollapse={isCollapse} />
            </Collapse>
          ))}
        </List>
      ))}
    </Box>
  );
}
