import { UpgradePlan, UpgradeStage } from 'api/models';

export const ARGOCD_PLAN: UpgradePlan = {
  id: 'upgr_e9e674d1-6ad5-4a47-a300-6b3f515179f3',
  name: 'plan-argocd-v2.5.1-to-v2.5.16',
  resources: {
    addon_instances: [
      {
        id: 'id-argocd_upgrade-1',
      },
    ],
  },
  deployment_strategy: 'in-place',
  status: 'completed',
  created: new Date(2023, 11, 25).getTime(),
  updated: new Date(2024, 0, 21).getTime(),
  last_validated: new Date(2024, 0, 19).getTime(),
  requested_by: {
    email: 'katy@chkk.io',
    id: '',
    name: 'Katy Smith',
    picture: '',
  },
  completed_by: {
    email: 'roger@chkk.io',
    id: '',
    name: 'Roger Walter',
    picture: '',
  },
};

export const ARGOCD_PLAN_STAGES: UpgradeStage[] = [
  // 0. Learn
  {
    stage_number: 0,
    name: 'Learn',
    status: 'completed',
    allowed_action_types: [],
    created: 42,
    updated: 42,

    footer_content_blocks: [
      {
        type: 'completionCtaCard',
        title: "🎉 You are all caught up on what's changing in ArgoCD v2.5.16",
        text: "Let's start preparing for your upgrade",
      },
    ],

    steps: [
      {
        step_number: 1,
        name: 'Why move from ArgoCD v2.5.1 to v2.5.16?',
        description:
          'We recommend moving to ArgoCD version v2.5.16 because it contains bug fixes for the following critical and high severity Defects:',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'completed',
        created: 42,
        updated: 42,
        comments: {},

        content_blocks: [
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Fix: Re-Added “project” filter field for backwards-compatibility. The original “project” filter was renamed to “projects” in v2.4.0 that turned out to be a breaking API change. ',
              },
              {
                text: 'Fix: Suppress Kubernetes API deprecation warnings for log levels that are less than Debug by customizing the cluster cache client warning handle',
              },
              {
                text: 'Fix for segmentation fault when running `argocd appset name <name>` when `spec.syncPolicy` is set but `spec.template.spec.syncPolicy` is not set',
              },
              {
                text: 'JWT audience claim is not verified. All versions of Argo CD starting with v1.8.2 are vulnerable to an improper authorization bug causing the API to accept certain invalid tokens',
              },
              {
                text: 'Controller reconciles apps outside configured namespaces when sharding is enabled',
              },
              {
                text: 'Application stuck in infinite reconciliation loop if using wrong project',
              },
            ],
          },
          {
            type: 'learn-more',
            links: [
              {
                text: 'ArgoCD releases',
                url: 'https://github.com/argoproj/argo-cd/releases',
              },
            ],
          },
        ],
      },
    ],
  },
  // 1. Prepare
  {
    stage_number: 1,
    name: 'Prepare',
    status: 'completed',
    allowed_action_types: [],
    created: 42,
    updated: 42,

    header_content_blocks: [
      {
        type: 'banner',
        title:
          'Recommended: ArgoCD Upgrade from v2.5.1 to v2.5.16. Helm chart upgrade from v5.13.5 to v5.19.15.',
        description:
          "ArgoCD patch releases do not introduce any breaking changes. So this upgrade path doesn't require scheduled downtime and doesn't have any special upgrade considerations.",
      },
    ],
    footer_content_blocks: [
      {
        type: 'completionCtaCard',
        title: 'Have you published  the new images for ArgoCD?',
        text: 'If yes, then you can proceed to the next Remediation step',
      },
    ],
    steps: [
      {
        requirement_level: 'required',
        step_number: 1,
        name: 'Review the recommended ArgoCD version',
        description: 'Deployment Tool: Helm',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'completed',
        created: 42,
        updated: 42,
        comments: {},

        content_blocks: [
          {
            type: 'table',
            column_definitions: [
              { title: 'ArgoCD Version' },
              { title: 'Recommended Version' },
              { title: 'Helm Chart Version' },
              { title: 'Recommended Helm Chart Version' },
            ],
            data: [
              [
                {
                  value: 'v2.5.1',
                },
                {
                  value: 'v2.5.16',
                },
                {
                  value: 'v5.13.5',
                },
                {
                  value: 'v5.19.15',
                },
              ],
            ],
          },
        ],
      },
      {
        requirement_level: 'required',
        step_number: 2,
        name: 'Ensure the artifacts are available',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'completed',
        created: 42,
        updated: 42,
        comments: {},

        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'You’re using a custom container registry to host the ArgoCD images and it appears you do not have an image for v2.5.16.',
          },
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Repository URL: 229476491445.dkr.ecr.us-east-1.amazonaws.com/public-argoproj',
              },
              { text: 'Available Image Tags: [argocd-v2.5.1]' },
            ],
          },
          {
            type: 'text',
            style: 'normal',
            text: 'You’ll need to build and publish the argocd-v2.5.16 version before proceeding to the next steps.',
          },
        ],
      },
    ],
  },
  // 2. Remediate
  {
    stage_number: 2,
    name: 'Remediate',
    status: 'completed',
    allowed_action_types: [],
    created: 42,
    updated: 42,

    footer_content_blocks: [
      {
        type: 'completionCtaCard',
        title: '🎉 Availability Risk has been successfully remediated',
        text: 'You can always refer back to completed Remediation Plans',
      },
    ],
    steps: [
      {
        requirement_level: 'required',
        step_number: 1,
        name: 'Remediate the Availability Risk',
        description:
          'You can either use an Assisted or a Manual Remediation workflow. We recommend using the Assisted Remediation for patch release version bumps.',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'completed',
        created: 42,
        updated: 42,
        comments: {},

        content_blocks: [
          {
            type: 'text',
            style: 'normal',
            text: 'You’ll need to run the following command or it’s equivalent in your Helm client. If you’re using Terraform or a different Helm provider, please adjust the command accordingly.',
          },
          {
            type: 'code',
            code: 'helm upgrade argocd argo/argo-cd --namespace argocd --version 5.19.15 --set global.image.tag=argocd-v2.5.16 –set global.image.repository=229476491445.dkr.ecr.us-east-1.amazonaws.com/public-argoproj <add the currently set values or use the values files>',
          },
        ],
      },
      {
        requirement_level: 'required',
        step_number: 2,
        name: 'Verify ArgoCD is healthy',
        description: '',
        allowed_action_types: ['mark-completed', 'undo-mark-completed'],
        status: 'completed',
        created: 42,
        updated: 42,
        comments: {},

        content_blocks: [
          {
            type: 'bullet-list',
            entries: [
              {
                text: 'Login to ArgoCD dashboard and make sure you’re able to see your deployments',
              },
              {
                text: 'Verify all the deployments, pods are running with no errors',
              },
            ],
          },
        ],
      },
    ],
  },
];
