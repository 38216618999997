import * as SubframeCore from '@subframe/core';
import {
  Alert,
  Badge,
  InfoTooltip,
  Breadcrumbs,
  InformationalHeader,
  Switch,
} from 'subframe/index';
import styles from './AcmeVersionRegisterControlPlaneV122.module.scss';
import { useParams } from 'react-router';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import Page from 'components/Page';
import { EnumEolState } from 'utils/types';
import { CircularProgress } from '@mui/material';
import {
  CLUSTERS,
  filterClustersByVersion,
} from 'src/data/version_register_cp';
import {
  getClusterExpiryStateBySupportLevel,
  getClusterLTSPeriodBadgeTooltipText,
  getClusterLTSSupportLevelName,
  getSupportBadgeForAccumulatedClusters,
  isClusterInOrPastLTSSupportPeriod,
} from 'utils/clusterSupportLevelAndEol';
import {
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';
import useLocalStorage from 'hooks/useLocalStorage';
import { AtomicTooltip } from 'components/design-system';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useEffect } from 'react';

import BaseTable from 'components/design-system/Table/BaseTable';
import { getClusterProvider, getClusterRegion } from 'utils/clusterStates';
import { fNumber } from 'utils/formatNumber';
import { useListClustersInfinite } from 'api/frontend-infinite';
import { clusterFlattenPages } from 'utils/arrays';

export default function VersionRegisterControlPlaneView() {
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();
  const basePath = useAccountIdRoute('/orgs/:orgId/accounts/:accountId');
  const parentPath = `${basePath}/artifact_register/control_plane`;

  const [showExampleData, setShowExampleData] = useLocalStorage<boolean>(
    'example-data',
    true,
  );
  const { data: clustersList } = useListClustersInfinite(
    '',
    { status: 'active' },
    {
      request: { headers: { Authorization: `Bearer ${account?.token}` } },
    },
    {
      initialSize: 100,
      revalidateFirstPage: false,
    },
  );
  const listClustersResponse = clusterFlattenPages(clustersList || []) || [];

  const realClusterData = listClustersResponse
    ? listClustersResponse.filter(
        (c) => c.internal_k8s_ref !== 'example-cluster-ref',
      )
    : [];
  useEffect(() => {
    if (listClustersResponse && localStorage.getItem('example-data') === null) {
      setShowExampleData(!realClusterData.length);
    }
  }, [listClustersResponse]);

  const { version } = useParams();
  const realVersion = (version || '').replace(/_/g, '.');
  const data = showExampleData ? CLUSTERS : realClusterData;

  const versionClusters = filterClustersByVersion(data, realVersion || '');

  const supportBadgeData =
    getSupportBadgeForAccumulatedClusters(versionClusters);

  const versionString =
    version === 'earlier'
      ? 'v1.21 and earlier unsupported versions'
      : `v${realVersion}`;

  useEffect(() => {
    logEvent('version-register-control-plane-viewed', {
      controlPlaneName: `Control Plane - K8s ${versionString}`,
      exampleData: showExampleData,
    });
  }, []);

  if (listClustersResponse === undefined) {
    return (
      <Page title={`Control Plane - K8s ${versionString}`}>
        <div className={styles['pageContents']} style={{ marginLeft: '32px' }}>
          <CircularProgress />
        </div>
      </Page>
    );
  }
  return (
    <Page title={`Control Plane - K8s ${versionString}`}>
      <div
        className={styles['pageContents']}
        style={{ marginLeft: '32px', width: 'calc(100% - 32px)' }}
      >
        <Breadcrumbs>
          <Breadcrumbs.Item>Artifact Register</Breadcrumbs.Item>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <RouterLink to={parentPath}>
            <Breadcrumbs.Item data-cy="breadcrum-control-plane">
              Control Plane
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider name="FeatherChevronRight" />
          <Breadcrumbs.Item active={true}>{versionString}</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className={styles['header']}>
          <div className={styles['stack-2cdc78bc']}>
            <div className={styles['stack-8aa3d59a']}>
              <span className="text-section-header font-section-header text-default-font">
                K8s {versionString}
              </span>
              {supportBadgeData && supportBadgeData.length && (
                <>
                  {supportBadgeData.map((supportLevel, index) => {
                    const showEnds =
                      supportLevel.badgeVariant === 'warning' &&
                      supportLevel?.end_date > new Date().getTime() / 1000;
                    return (
                      <Badge
                        key={'provider-badge-' + index}
                        variant={supportLevel.badgeVariant || 'neutral'}
                        data-cy="provider-badge"
                      >
                        {`${showEnds ? '' : 'End of'} ${supportLevel.name}: ${
                          showEnds ? 'Ends' : ''
                        } ${formatDistanceToNowStrict(
                          new Date(supportLevel.end_date * 1000),
                          { addSuffix: true },
                        )} ${format(
                          new Date(supportLevel.end_date * 1000),
                          '(LLL yyyy)',
                        )}`}
                      </Badge>
                    );
                  })}
                </>
              )}
            </div>
            <div className={styles['stack-8b2cc7fe']}>
              <span className={styles['text-f6b42336']}>
                Clusters running {versionString}
              </span>
              <div className={styles['stack']}>
                <span className={styles['text']}>Show example data</span>
                <AtomicTooltip
                  tooltipContent={
                    showExampleData
                      ? 'Show results from your onboarded clusters'
                      : 'Show example data'
                  }
                >
                  <Switch
                    checked={showExampleData}
                    onClick={() => setShowExampleData(!showExampleData)}
                  />
                </AtomicTooltip>
              </div>
            </div>
          </div>
        </div>
        {showExampleData && (
          <Alert
            variant="warning"
            title="You are currently viewing example data"
            description={`To view results from your onboarded clusters, toggle the "Show example data" button`}
          />
        )}
        <div className={styles['stack-0f954c5b']}>
          <span className={styles['subheaderText']} />
          <InformationalHeader>
            <InformationalHeader.Item
              title="Total"
              bodySlot={
                <span className="text-body-bold font-body-bold text-default-font">
                  {fNumber(versionClusters.length)}
                </span>
              }
            />
            {versionClusters.filter((c) =>
              // using created as the last upgrade date is not available today
              isAfter(new Date(c.created * 1000), subDays(new Date(), 30)),
            ).length > 0 && (
              <InformationalHeader.Item
                title="Added Last 30d"
                headingSlot={
                  <InfoTooltip
                    tooltipText={`Clusters running ${versionString} added/upgraded in the past 30 days`}
                  />
                }
                icon={
                  <SubframeCore.Icon
                    className={styles['icon']}
                    name="FeatherTrendingUp"
                  />
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {fNumber(
                      versionClusters.filter((c) =>
                        // using created as the last upgrade date is not available today
                        isAfter(
                          new Date(c.created * 1000),
                          subDays(new Date(), 30),
                        ),
                      ).length,
                    )}
                  </span>
                }
              />
            )}
            {versionClusters.filter((c) =>
              // using created as the last upgrade date is not available today
              isBefore(new Date(c.created * 1000), subDays(new Date(), 30)),
            ).length > 0 && (
              <InformationalHeader.Item
                title="Added 30d+"
                headingSlot={
                  <InfoTooltip
                    tooltipText={`Clusters running ${versionString} for more than 30 days`}
                  />
                }
                bodySlot={
                  <span className="text-body-bold font-body-bold text-default-font">
                    {
                      versionClusters.filter((c) =>
                        // using created as the last upgrade date is not available today
                        isBefore(
                          new Date(c.created * 1000),
                          subDays(new Date(), 30),
                        ),
                      ).length
                    }
                  </span>
                }
              />
            )}
          </InformationalHeader>
        </div>
        <div className={styles['stack-d98be3d3']}>
          <BaseTable
            settings={{
              localStorageKey: 'artifact_register_control_plane_view',
              dataCyPrefix: 'ar-control-plane-view',
            }}
            data={versionClusters}
            rowLink={(row) => `${basePath}/clusters/${row.id}`}
            rowSettings={{
              preventClick: (row) => showExampleData,
              preventClickToastMessage:
                'This is a demonstration view only at this point',
            }}
            columns={[
              {
                id: '1',
                title: 'Name',
                titleStyle: { width: '60%' },
                cellType: 'cell',
                render: (row) => {
                  // ! this is where the color for the clock in each row is determined
                  const expiryState = getClusterExpiryStateBySupportLevel(row);
                  return (
                    <div className={styles['stack-7b395a22']}>
                      {expiryState === EnumEolState.Warning && (
                        <SubframeCore.Icon
                          className={styles['icon']}
                          name="FeatherClock"
                        />
                      )}
                      {expiryState === EnumEolState.Error && (
                        <SubframeCore.Icon
                          className={styles['icon-5dad1203']}
                          name="FeatherClock"
                        />
                      )}
                      <span
                        className={styles['bodyText']}
                        data-cy="view-cluster"
                      >
                        {row.name}
                      </span>
                      <AtomicTooltip
                        tooltipContent={row.environment || 'default'}
                      >
                        <Badge
                          data-cy="view-environment"
                          variant="neutral"
                          style={{
                            maxWidth: '30%',
                          }}
                        >
                          {row.environment || 'default'}
                        </Badge>
                      </AtomicTooltip>
                      {isClusterInOrPastLTSSupportPeriod(row) && (
                        <AtomicTooltip
                          tooltipContent={getClusterLTSPeriodBadgeTooltipText(
                            row,
                          )}
                        >
                          <Badge
                            data-cy="view-lts-period-badge"
                            variant={
                              expiryState === EnumEolState.Default
                                ? 'neutral'
                                : expiryState
                            }
                            style={{
                              maxWidth: '30%',
                            }}
                          >
                            {getClusterLTSSupportLevelName(row)}
                          </Badge>
                        </AtomicTooltip>
                      )}
                    </div>
                  );
                },
              },
              {
                id: '2',
                title: 'Provider : Region',
                titleStyle: { width: '40%' },
                cellType: 'cell',
                render: (row) => {
                  return (
                    <span
                      data-cy="provider"
                      className={styles['bodyText']}
                    >{`${getClusterProvider(row)} : ${getClusterRegion(
                      row,
                    )}`}</span>
                  );
                },
              },
            ]}
            actions={[
              {
                id: 'navigate',
                render: () => {
                  return (
                    <div className={styles['stack-83b62c08']}>
                      <SubframeCore.Icon
                        className={styles['bodyText']}
                        name="FeatherChevronRight"
                      />
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </Page>
  );
}
