/* Release: 8ad4bfd8 (Latest – unreleased) */

import * as SubframeCore from '@subframe/core';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from 'subframe/components/IconButton';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import deleteDialogStyles from '../helpers/UpgradesCancelDialog.module.scss';
import dotMenuStyles from './DotMenu.module.scss';
import createEditStyles from './CreateUpdateStepDialog.module.scss';
import { Checkbox, DropdownMenu, TextArea, TextField } from 'subframe/index';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { UpgradePlan, UpgradeStageStep, UpgradeTemplate } from 'api/models';
import { useForm } from 'react-hook-form';
import FormProvider from 'components/FormProvider';
import {
  createUpgradePlanStageStep,
  createUpgradeTemplateStageStep,
  deleteUpgradePlanStageStep,
  deleteUpgradeTemplateStageStep,
  updateUpgradePlanStageStep,
  updateUpgradeTemplateStageStep,
} from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { TabsWithContent } from 'components/design-system/Tabs';
import { UpgradePlanStepMarkdown } from './StepMarkdown';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { AtomicTooltip } from 'components/design-system';
import UpgradeTemplateSkipStepDialog from 'pages/upgrades/components/UpgradeTemplateSkipStepDialog';
import { Account } from 'models/account';
import {
  charLimitExceededErrorMessage,
  charLimits,
  fieldIsRequiredErrorMessage,
} from 'constants/input-validation';
import { isValidMarkdown } from 'utils/inputValidation';
import DoNotMarkForUpgradeDialog from './DoNotMarkForUpgradeDialog';

export default function UpgradesDotMenu(props: {
  onChanges: () => void;
  account?: Account;
  upgrade: UpgradePlan | UpgradeTemplate;
  stageNumber: number;
  step: UpgradeStageStep;
  exampleMode: boolean;
  isTemplate?: boolean;
}) {
  const [dialogOpen, setDialogOpen] = useState<
    'delete' | 'create' | 'edit' | 'skip' | undefined
  >(undefined);
  const { logEvent } = AnalyticsEventLogger();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [addStepPosition, setAddStepPosition] = useState<'before' | 'after'>(
    'after',
  );

  if (
    props.upgrade.status === 'pending' ||
    props.upgrade.status === 'cancellation-in-progress' ||
    props.upgrade.status === 'cancelled' ||
    props.upgrade.status === 'completed' ||
    props.upgrade.status === 'regenerate-in-progress'
  ) {
    return <></>;
  }

  return (
    <>
      <SubframeCore.DropdownMenu.Root>
        <SubframeCore.DropdownMenu.Trigger asChild={true}>
          <IconButton icon="FeatherMoreVertical" />
        </SubframeCore.DropdownMenu.Trigger>
        <SubframeCore.DropdownMenu.Portal>
          <SubframeCore.DropdownMenu.Content
            side="bottom"
            align="start"
            sideOffset={8}
            asChild={true}
          >
            <DropdownMenu className="gap-y-1">
              {props.step.status === 'not-marked-for-upgrade' && (
                <DropdownMenu.DropdownItem
                  disabled={props.exampleMode}
                  icon="FeatherCheckSquare"
                  onClick={async () => {
                    if (props.exampleMode || !props.account) {
                      return;
                    }
                    setIsLoading(true);
                    try {
                      await updateUpgradeTemplateStageStep(
                        props.upgrade.id,
                        props.stageNumber.toString(),
                        props.step.step_number.toString(),
                        { status: 'marked-for-upgrade' },
                        {
                          headers: {
                            Authorization: `Bearer ${props.account.token}`,
                          },
                        },
                      );
                      logEvent('step-action-mark-for-upgrade', {
                        upgradeId: props.upgrade.id,
                        stageNumber: props.stageNumber,
                        stepNumber: props.step.step_number,
                      });
                      props.onChanges();
                      enqueueSnackbar(`Successfully marked as 'For Upgrade'`, {
                        variant: 'success',
                        autoHideDuration: toastAutoHideDuration,
                      });
                    } catch (error) {
                      Sentry.captureException(error);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                >
                  Mark for Upgrade
                </DropdownMenu.DropdownItem>
              )}
              {props.step.status === 'marked-for-upgrade' && (
                <DoNotMarkForUpgradeDialog
                  variant="neutral-tertiary"
                  account={props?.account || {}}
                  upgrade={props?.upgrade}
                  stageNumber={props.stageNumber}
                  stepNumber={props.step.step_number}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onConfirm={() => {
                    props.onChanges();
                    logEvent('step-action-not-mark-for-upgrade', {
                      upgradeId: props.upgrade.id,
                      stageNumber: props.stageNumber,
                      stepNumber: props.step.step_number,
                    });
                  }}
                />
              )}
              <AtomicTooltip tooltipContent="Add a custom step before this step">
                <DropdownMenu.DropdownItem
                  disabled={props.exampleMode}
                  icon="FeatherArrowUp"
                  onClick={() => {
                    if (props.exampleMode) {
                      return;
                    }
                    setDialogOpen('create');
                    setAddStepPosition('before');
                    logEvent('step-action-add-step-before', {
                      upgradeId: props.upgrade.id,
                      stageNumber: props.stageNumber,
                      stepNumber: props.step.step_number,
                    });
                  }}
                >
                  Add Step Before
                </DropdownMenu.DropdownItem>
              </AtomicTooltip>
              <AtomicTooltip tooltipContent="Add a custom step after this step">
                <DropdownMenu.DropdownItem
                  disabled={props.exampleMode}
                  icon="FeatherArrowDown"
                  onClick={() => {
                    if (props.exampleMode) {
                      return;
                    }

                    setDialogOpen('create');
                    setAddStepPosition('after');
                    logEvent('step-action-add-step-after', {
                      upgradeId: props.upgrade.id,
                      stageNumber: props.stageNumber,
                      stepNumber: props.step.step_number,
                    });
                  }}
                >
                  Add Step After
                </DropdownMenu.DropdownItem>
              </AtomicTooltip>
              {props.isTemplate &&
                props.step.allowed_action_types?.includes(
                  'open-upgrade-plan', // Skip should be allowed only for Template steps that are Add-on Upgrade Plans, so they don't get published with Cluster Upgrade Plan.
                ) &&
                props.step.allowed_action_types?.includes('skip') &&
                props.step.status !== 'skipped' && (
                  <AtomicTooltip tooltipContent="Skip this Step">
                    <DropdownMenu.DropdownItem
                      disabled={props.exampleMode}
                      onClick={() => {
                        if (props.exampleMode) {
                          return;
                        }
                        setDialogOpen('skip');
                        logEvent('step-action-skip-step', {
                          upgradeId: props.upgrade.id,
                          stageNumber: props.stageNumber,
                          stepNumber: props.step.step_number,
                        });
                      }}
                      icon="FeatherSkipForward"
                    >
                      Skip
                    </DropdownMenu.DropdownItem>
                  </AtomicTooltip>
                )}
              {props.step.owner === 'customer' && (
                <>
                  <DropdownMenu.DropdownItem
                    disabled={props.exampleMode}
                    icon="FeatherEdit2"
                    onClick={() => {
                      if (props.exampleMode) {
                        return;
                      }
                      setDialogOpen('edit');
                      logEvent('step-action-edit-step', {
                        upgradeId: props.upgrade.id,
                        stageNumber: props.stageNumber,
                        stepNumber: props.step.step_number,
                      });
                    }}
                  >
                    Edit Step
                  </DropdownMenu.DropdownItem>
                  <div className={dotMenuStyles['stack-1457643d']}>
                    <div className={dotMenuStyles['divider']} />
                  </div>
                  <DropdownMenu.DropdownItem
                    disabled={props.exampleMode}
                    icon="FeatherX"
                    onClick={async () => {
                      if (props.exampleMode) {
                        return;
                      }

                      await props.onChanges();
                      setDialogOpen('delete');
                      logEvent('step-action-delete-step', {
                        upgradeId: props.upgrade.id,
                        stageNumber: props.stageNumber,
                        stepNumber: props.step.step_number,
                      });
                    }}
                  >
                    Delete Step
                  </DropdownMenu.DropdownItem>
                </>
              )}
            </DropdownMenu>
          </SubframeCore.DropdownMenu.Content>
        </SubframeCore.DropdownMenu.Portal>
      </SubframeCore.DropdownMenu.Root>
      <UpgradeTemplateSkipStepDialog
        token={props.account?.token || ''}
        open={dialogOpen === 'skip'}
        setOpen={(bool) => setDialogOpen(bool ? 'skip' : undefined)}
        upgradeId={props.upgrade.id}
        stageNumber={props.stageNumber}
        stepNumber={props.step.step_number}
        onSuccess={async () => {
          await props.onChanges();
          setDialogOpen(undefined);
        }}
      />
      <UpgradesDeleteStepDialog
        isTemplate={props.isTemplate}
        open={dialogOpen === 'delete'}
        setOpen={(bool) => setDialogOpen(bool ? 'delete' : undefined)}
        onConfirm={async () => {
          await props.onChanges();
          setDialogOpen(undefined);
        }}
        upgradeId={props.upgrade.id}
        stageNumber={props.stageNumber}
        step={props.step}
      />
      <UpgradesCreateEditStepDialog
        isTemplate={props.isTemplate}
        open={dialogOpen === 'create' || dialogOpen === 'edit'}
        mode={dialogOpen === 'create' ? 'create' : 'edit'}
        setOpen={() => setDialogOpen(undefined)}
        onConfirm={async () => {
          await props.onChanges();
          setDialogOpen(undefined);
        }}
        upgradeId={props.upgrade.id}
        stageNumber={props.stageNumber}
        step={props.step}
        addStepPosition={addStepPosition}
      />
    </>
  );
}

function UpgradesCreateEditStepDialog(props: {
  addStepPosition: 'before' | 'after';
  isTemplate?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => Promise<void>;

  mode: 'create' | 'edit';
  upgradeId: string;
  stageNumber: number;
  step: UpgradeStageStep;
}) {
  const { account } = useUserAccountState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const createEditStepSchema = Yup.object().shape({
    title: Yup.string()
      .required(fieldIsRequiredErrorMessage('Step Title'))
      .max(
        charLimits.Title,
        charLimitExceededErrorMessage('Step Title', charLimits.Title),
      ),
    badge: Yup.string()
      .optional()
      .max(
        charLimits.Badge,
        charLimitExceededErrorMessage('Badge', charLimits.Badge),
      ),
    required: Yup.boolean(),
    content: Yup.string()
      .max(
        charLimits.LongDescription,
        charLimitExceededErrorMessage(
          'Step Content',
          charLimits.LongDescription,
        ),
      )
      .test(
        'is-valid-markdown',
        'Step description must be a valid markdown',
        (value) => (value ? isValidMarkdown(value) : true),
      ),
  });

  const defaultValues = {
    title: props.mode === 'edit' ? props.step.name : '',
    badge:
      props.mode === 'edit'
        ? (props.step.badges || []).length > 0
          ? (props.step.badges || [])[0].text
          : ''
        : '',
    required:
      props.mode === 'edit'
        ? props.step.requirement_level === 'required'
        : false,
    content: props.mode === 'edit' ? props.step.notes?.content || '' : '',
  };

  const methods = useForm({
    resolver: yupResolver(createEditStepSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    reset,
    clearErrors,
  } = methods;

  const onSubmitUpgradeTemplateStep = async (data: {
    title: string;
    badge: string | undefined;
    required: boolean;
    content: string;
  }) => {
    setLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (props.mode === 'create') {
        const newStep = await createUpgradeTemplateStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          {
            insert_after_id:
              props.addStepPosition === 'after'
                ? props.step.step_number
                : undefined,
            insert_before_id:
              props.addStepPosition === 'before'
                ? props.step.step_number
                : undefined,
            name: data.title,
            requirement_level: data.required ? 'required' : 'optional',
            badges:
              data.badge === undefined || data.badge === ''
                ? []
                : [{ text: data.badge, variant: 'neutral' }],
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        await updateUpgradeTemplateStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          newStep.step_number.toFixed(),
          {
            notes: {
              value: data.content,
            },
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        logEvent('add-upgrade-template-step', {
          templateId: props.upgradeId,
          stageNumber: props.stageNumber,
          customStepPosition: `${props.addStepPosition} step ${props.step.step_number}: ${props.step.name}`,
        });
      } else {
        await updateUpgradeTemplateStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          props.step.step_number.toFixed(),
          {
            name: data.title,
            requirement_level: data.required ? 'required' : 'optional',
            badges:
              data.badge === undefined || data.badge === ''
                ? []
                : [{ text: data.badge, variant: 'neutral' }],
            notes: {
              value: data.content,
            },
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        logEvent('edit-upgrade-template-step', {
          templateId: props.upgradeId,
          stageNumber: props.stageNumber,
          stepNumber: props.step.step_number,
        });
      }
      await props.onConfirm();
      enqueueSnackbar(
        props.mode === 'create'
          ? 'Upgrade Template Step was added successfully'
          : 'Upgrade Template Step was updated successfully',
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      props.setOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      let errorMessage =
        props.mode === 'create'
          ? somethingWentWrong.replace('<action>', 'adding new Step')
          : somethingWentWrong.replace('<action>', 'updating Step');
      if (error.response?.status === 413) {
        errorMessage =
          'Custom step can’t exceed 75KBs. Reduce the step’s content';
        props.setOpen(false);
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitUpgradePlanStep = async (data: {
    title: string;
    badge: string | undefined;
    required: boolean;
    content: string;
  }) => {
    setLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (props.mode === 'create') {
        const newStep = await createUpgradePlanStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          {
            insert_after_id:
              props.addStepPosition === 'after'
                ? props.step.step_number
                : undefined,
            insert_before_id:
              props.addStepPosition === 'before'
                ? props.step.step_number
                : undefined,
            name: data.title,
            requirement_level: data.required ? 'required' : 'optional',
            badges:
              data.badge === undefined || data.badge === ''
                ? []
                : [{ text: data.badge, variant: 'neutral' }],
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        await updateUpgradePlanStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          newStep.step_number.toFixed(),
          {
            notes: {
              value: data.content,
            },
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        logEvent('add-upgrade-plan-step', {
          planId: props.upgradeId,
          stageNumber: props.stageNumber,
          customStepPosition: `${props.addStepPosition} step ${props.step.step_number}: ${props.step.name}`,
        });
      } else {
        await updateUpgradePlanStageStep(
          props.upgradeId,
          props.stageNumber.toFixed(),
          props.step.step_number.toFixed(),
          {
            name: data.title,
            requirement_level: data.required ? 'required' : 'optional',
            badges:
              data.badge === undefined || data.badge === ''
                ? []
                : [{ text: data.badge, variant: 'neutral' }],
            notes: {
              value: data.content,
            },
          },
          {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        );
        logEvent('edit-upgrade-plan-step', {
          planId: props.upgradeId,
          stageNumber: props.stageNumber,
          stepNumber: props.step.step_number,
        });
      }
      await props.onConfirm();
      enqueueSnackbar(
        props.mode === 'create'
          ? 'Upgrade Step was added successfully'
          : 'Upgrade Step was updated successfully',
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      props.setOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      let errorMessage =
        props.mode === 'create'
          ? somethingWentWrong.replace('<action>', 'adding new Step')
          : somethingWentWrong.replace('<action>', 'updating Step');
      if (error.response?.status === 413) {
        errorMessage =
          'Custom step can’t exceed 75KBs. Reduce the step’s content';
        props.setOpen(false);
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
    }
  };

  const resetToDefault = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    clearErrors();
    resetToDefault();
  }, [props.open]);

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Content className="h-[60vh] w-[60%] flex-[0_0_auto]">
        <FormProvider
          id={'edit-step-dialog-form'}
          methods={methods}
          onSubmit={handleSubmit(
            props.isTemplate
              ? onSubmitUpgradeTemplateStep
              : onSubmitUpgradePlanStep,
          )}
          style={{ width: '100%', height: '100%' }}
        >
          <div className={createEditStyles['stack-60cf4a74']}>
            <div className={createEditStyles['stack-a155e211']}>
              <div className={createEditStyles['stack-465a720e']}>
                <span className={createEditStyles['text-6e439461']}>
                  {props.mode === 'create'
                    ? `Add step ${props.addStepPosition} ${props.step.name}`
                    : `Edit step`}
                </span>
                <IconButton
                  icon="FeatherX"
                  size="medium"
                  onClick={() => {
                    clearErrors();
                    resetToDefault();

                    props.setOpen(false);
                  }}
                  disabled={loading || isSubmitting}
                />
              </div>
            </div>
            <div className={createEditStyles['stack-5902fa1a']}>
              <TextField
                className="h-auto w-full flex-none"
                label="Title"
                helpText={errors.title?.message}
                error={methods.getFieldState('title').invalid}
              >
                <TextField.Input
                  disabled={loading || isSubmitting}
                  placeholder="Enter step title"
                  {...methods.register('title')}
                />
              </TextField>
              <TextField
                className="h-auto w-full flex-none"
                label="Badge (optional)"
                helpText={errors.badge?.message}
                error={methods.getFieldState('badge').invalid}
              >
                <TextField.Input
                  disabled={loading || isSubmitting}
                  placeholder="Enter text for badge if needed"
                  {...methods.register('badge')}
                />
              </TextField>
              <div className={createEditStyles['stack-db1a9c48']}>
                <Checkbox
                  label={''}
                  disabled={loading || isSubmitting}
                  checked={getValues('required')}
                  onCheckedChange={(state) =>
                    setValue(
                      'required',
                      state === 'indeterminate' ? false : state,
                      { shouldValidate: true },
                    )
                  }
                />
                <span
                  className={createEditStyles['text-5694c3c2']}
                  onClick={() => {
                    if (!loading && !isSubmitting) {
                      setValue('required', !getValues('required'), {
                        shouldValidate: true,
                      });
                    }
                  }}
                >
                  Required
                </span>
              </div>
              <TabsWithContent
                tabs={[
                  {
                    id: 'write',
                    title: 'Write',
                    content: (
                      <>
                        <TextArea
                          className="w-full h-full"
                          label={''}
                          error={methods.getFieldState('content').invalid}
                          helpText={errors.content?.message}
                        >
                          <ControlledTextAreaInput
                            disabled={loading || isSubmitting}
                            className="w-full h-full"
                            value={getValues('content')}
                            onInput={(e) =>
                              setValue('content', e.target.value, {
                                shouldValidate: true,
                              })
                            }
                            placeholder="Write your step details here. Markdown supported!"
                            {...methods.register('content')}
                          />
                        </TextArea>
                      </>
                    ),
                  },
                  {
                    id: 'preview',
                    title: 'Preview',
                    content: (
                      <div
                        style={{
                          width: '100%',
                          overflow: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          border: 'var(--gray-border)',
                          borderRadius: 'var(--rounded)',
                          padding: '10px 12px 10px 12px',
                        }}
                      >
                        <UpgradePlanStepMarkdown
                          content={
                            getValues('content') ||
                            'No step details entered so far'
                          }
                          className={createEditStyles['markdown']}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className={createEditStyles['stack-f1881db4']}>
              <Button
                size="large"
                variant="brand-primary"
                icon={props.mode === 'create' ? 'FeatherPlus' : 'FeatherEdit'}
                loading={loading || isSubmitting}
                disabled={loading || isSubmitting}
                type="submit"
              >
                {props.mode === 'create' ? 'Add Step' : 'Update Step'}
              </Button>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}

function UpgradesDeleteStepDialog(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => Promise<void>;
  upgradeId: string;
  stageNumber: number;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}) {
  const { account } = useUserAccountState();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const confirmDeleteUpgradePlaneStep = async () => {
    setLoading(true);

    try {
      await deleteUpgradePlanStageStep(
        props.upgradeId,
        props.stageNumber.toFixed(),
        props.step.step_number.toFixed(),
        {
          headers: { Authorization: `Bearer ${account.token}` },
        },
      );
      await props.onConfirm();
      logEvent('delete-upgrade-plan-step', {
        planId: props.upgradeId,
        stageNumber: props.stageNumber,
        stepNumber: props.step.step_number,
      });

      enqueueSnackbar('Upgrade Step was deleted successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      props.setOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'deleting this Step'),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      setLoading(false);
    }
  };
  const confirmDeleteUpgradeTemplateStep = async () => {
    setLoading(true);

    try {
      await deleteUpgradeTemplateStageStep(
        props.upgradeId,
        props.stageNumber.toFixed(),
        props.step.step_number.toFixed(),
        {
          headers: { Authorization: `Bearer ${account.token}` },
        },
      );
      await props.onConfirm();
      logEvent('delete-upgrade-template-step', {
        templateId: props.upgradeId,
        stageNumber: props.stageNumber,
        stepNumber: props.step.step_number,
      });

      enqueueSnackbar('Upgrade Step was deleted successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      props.setOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'deleting this Step'),
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Content className="flex-[0_0_auto] h-auto w-[320px]">
        <div className={deleteDialogStyles['stack-fe8d2707']}>
          <div className={deleteDialogStyles['stack-a5652404']}>
            <span className={deleteDialogStyles['text-67f523a2']}>
              Delete the step?
            </span>
            <IconButton
              size="medium"
              icon="FeatherX"
              onClick={() => {
                props.setOpen(false);
              }}
              disabled={loading}
            />
          </div>
          <div className={deleteDialogStyles['stack']}>
            <span className={deleteDialogStyles['bodyText']}>
              Are you sure you want to delete this step?
            </span>
            <span className={deleteDialogStyles['bodyText']}>
              Deletion will remove this step from the Upgrade
              {props.isTemplate ? ' Template' : ' Plan'} and it will not be
              accessible to you or your team members.
            </span>
            <div className={deleteDialogStyles['stack-88dea843']}>
              <Button
                variant="neutral-tertiary"
                onClick={() => props.setOpen(false)}
                loading={loading}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="destructive-primary"
                icon="FeatherX"
                onClick={
                  props.isTemplate
                    ? confirmDeleteUpgradeTemplateStep
                    : confirmDeleteUpgradePlaneStep
                }
                loading={loading}
                disabled={loading}
              >
                Delete Step
              </Button>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

interface ControlledTextAreaInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  className?: string;
}

function ControlledTextAreaInput(props: ControlledTextAreaInputProps) {
  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCursor(e.target.selectionStart);
    onChange?.(e);
  };

  return (
    <TextArea.Input ref={ref} value={value} onChange={handleChange} {...rest} />
  );
}
