const DB_NAME = 'ChkkAuthDB';
const STORE_NAME = 'AuthStore';
const TOKEN_KEY = 'chkk_user_token';

export interface TokenData {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  expiry?: number;
}

class IndexedDBUtil {
  private db: IDBDatabase | null = null;

  async openDB(): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, 1);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        this.db = request.result;
        resolve();
      };
      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        db.createObjectStore(STORE_NAME);
      };
    });
  }

  async setToken(tokenData: TokenData): Promise<void> {
    if (!this.db) await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.put(tokenData, TOKEN_KEY);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }

  async getToken(): Promise<TokenData | null> {
    if (!this.db) await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([STORE_NAME], 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.get(TOKEN_KEY);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result || null);
    });
  }

  async removeToken(): Promise<void> {
    if (!this.db) await this.openDB();
    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction([STORE_NAME], 'readwrite');
      const store = transaction.objectStore(STORE_NAME);
      const request = store.delete(TOKEN_KEY);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }
}

export const indexedDBUtil = new IndexedDBUtil();
