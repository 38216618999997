import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// type
import { NavItemProps } from '../type';
//
import Iconify from '../../Iconify';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';
import { isExternalLink } from '..';
import useAccountIdRoute from 'src/hooks/useAccountIdRoute';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

// ----------------------------------------------------------------------

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}: NavItemProps) {
  const theme = useTheme();

  const { id, title, path, icon, info, trackerEvent, children, disabled } =
    item;

  const { logEvent } = AnalyticsEventLogger();
  const newPath = useAccountIdRoute(path);

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle isCollapse={isCollapse}>
        <Typography sx={{ color: theme.palette.grey[100] }} variant="body1">
          {title}
        </Typography>
      </ListItemTextStyle>
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      disabled={disabled}
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      onClick={() => logEvent(trackerEvent)}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      id={id}
      disabled={disabled}
      component={RouterLink}
      to={newPath}
      activeRoot={active}
      onClick={() => logEvent(trackerEvent)}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, 'isCollapse'>;

export function NavItemSub({
  item,
  open = false,
  active = false,
  onOpen,
}: NavItemSubProps) {
  const { id, title, path, info, icon, children } = item;
  const theme = useTheme();

  const newPath = useAccountIdRoute(path);

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle>
        <Typography sx={{ color: theme.palette.grey[100] }} variant="body1">
          {title}
        </Typography>
      </ListItemTextStyle>
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      id={id}
      component={RouterLink}
      to={newPath}
      activeRoot={active}
      subItem
      sx={{
        marginLeft: '30px',
      }}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
