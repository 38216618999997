export const K8S_VERSIONS = [
  '1.22',
  '1.23',
  '1.24',
  '1.25',
  '1.26',
  '1.27',
  '1.28',
  '1.29',
  '1.30',
  '1.31',
  '1.32',
];
