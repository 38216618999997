import { UpgradePlan, UpgradeTemplate } from 'api/models';

export function filterUpgradePlansByResources(upgradePlans: UpgradePlan[]) {
  const addonUpgradePlans: UpgradePlan[] = [];
  const clusterUpgradePlans: UpgradePlan[] = [];

  upgradePlans?.forEach((plan) => {
    const addonResourcesCount =
      (plan.resources?.addons || []).length +
      (plan.resources?.addon_instances || []).length;

    if (addonResourcesCount === 0) {
      clusterUpgradePlans.push(plan);
    } else {
      addonUpgradePlans.push(plan);
    }
  });

  return {
    addonUpgradePlans,
    clusterUpgradePlans,
  };
}

export function filterUpgradeTemplatesByResources(
  upgradeTemplates: UpgradeTemplate[],
) {
  const addonUpgradeTemplates: UpgradeTemplate[] = [];
  const clusterUpgradeTemplates: UpgradeTemplate[] = [];

  upgradeTemplates?.forEach((template) => {
    const addonResourcesCount =
      (template.resources?.addons || []).length +
      (template.resources?.addon_instances || []).length;

    if (addonResourcesCount === 0) {
      clusterUpgradeTemplates.push(template);
    } else {
      addonUpgradeTemplates.push(template);
    }
  });

  return {
    addonUpgradeTemplates,
    clusterUpgradeTemplates,
  };
}
