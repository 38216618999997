import {
  createUpgradePlanStageStepComment,
  createUpgradeTemplateStageStepComment,
  deleteUpgradePlanStageStepLastComment,
  deleteUpgradeTemplateStageStepLastComment,
  useListUpgradePlanStageStepComments,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStep,
  UpgradeTemplate,
} from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Avatar,
  Button,
  ChatList,
  IconButton,
  Loader,
  SeeAllButton,
  TextArea,
} from 'subframe/index';
import { useState } from 'react';
import { somethingWentWrong } from 'constants/toasts';
import {
  getHumanReadableDateAndTime,
  getRelativeTimeString,
} from 'utils/formatTime';
import { AtomicTooltip } from 'components/design-system';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import {
  charLimitExceededErrorMessage,
  charLimits,
} from 'constants/input-validation';
import { fromUnixTime } from 'date-fns';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getSeeMoreCommentsText } from '../helpers/UpgradesHelpers';

export default function StepDrawerCommentsForTeam(props: {
  showExampleData: boolean;
  upgrade: UpgradePlan | UpgradeTemplate;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  commentThreadId?: string;
  isTemplate?: boolean;
  onChange: () => void;
}) {
  const { logEvent } = AnalyticsEventLogger();

  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleting, setIsDeleting] = useState(false);
  const [seeAllMessages, setSeeAllMessages] = useState(false);
  const { data: commentsData, mutate: commentsMutate } = props.isTemplate
    ? useListUpgradeTemplateStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        },
      )
    : useListUpgradePlanStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );
  const postCommentSchema = Yup.object().shape({
    comment: Yup.string()
      .optional()
      .max(
        charLimits.ActionDetails,
        charLimitExceededErrorMessage('Comments', charLimits.ActionDetails),
      ),
  });

  const defaultValues: { comment: string } = {
    comment: '',
  };

  const methods = useForm<{ comment: string }>({
    resolver: yupResolver(postCommentSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    clearErrors,
    resetField,
    formState: { isSubmitting, errors },
  } = methods;
  const postComment = async (data: { comment: string }) => {
    try {
      if (props.isTemplate) {
        await createUpgradeTemplateStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment: data.comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      } else {
        await createUpgradePlanStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment: data.comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      }

      await commentsMutate();
      props.onChange();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'posting comment'),
        { variant: 'error' },
      );
    } finally {
      resetField('comment');
      clearErrors();
    }
  };

  const disableActionsString =
    props.upgrade.status === 'pending' ||
    props.upgrade.status === 'cancelled' ||
    props.upgrade.status === 'completed' ||
    props.upgrade.status === 'regenerate-in-progress'
      ? `The Upgrade ${
          props.isTemplate ? 'Template' : 'Plan'
        } is read-only and can no longer be commented on.`
      : undefined;

  const commentsList =
    commentsData?.data?.filter((item) => !item.is_for_chkk) || [];

  if (props.showExampleData) {
    return <></>;
  }

  const deleteComment = async () => {
    try {
      if (commentsList[commentsList.length - 1].user.id !== user.userId) {
        enqueueSnackbar(
          'The comment can only be deleted by the user who posted it',
          { variant: 'error' },
        );
        return;
      }

      setIsDeleting(true);
      if (props.isTemplate) {
        await deleteUpgradeTemplateStageStepLastComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          props.commentThreadId || '',
          {},
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      } else {
        await deleteUpgradePlanStageStepLastComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          props.commentThreadId || '',

          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      }
      await commentsMutate();
      props.onChange();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'deleting comment'),
        { variant: 'error' },
      );
    } finally {
      logEvent('delete-comment', {
        is_for_chkk: false,
        upgrade_id: props.upgrade.id,
        stage_number: props.stage.stage_number,
        step_number: props.step.step_number,
        comment_thread_id: props.commentThreadId,
      });
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
      <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border">
        <Accordion
          trigger={
            <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Leave Comment for your team
              </span>
              <Accordion.Chevron />
            </div>
          }
          defaultOpen={true}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 px-6 pb-6">
            <div className="flex w-full flex-col items-start gap-4">
              {commentsData ? (
                <>
                  <div className="flex w-full items-end justify-end gap-2">
                    <div className="flex grow shrink-0 basis-0 flex-col items-start gap-4">
                      <ChatList className="h-auto w-full flex-none">
                        {commentsList.length > 3 ? (
                          <>
                            <div className="flex w-full items-start justify-end px-1 py-1">
                              <ChatList.ChatListItem
                                name={commentsList[0].user.name}
                                avatar={
                                  <Avatar
                                    image={commentsList[0].user.picture}
                                  />
                                }
                                time={
                                  <AtomicTooltip
                                    tooltipContent={getHumanReadableDateAndTime(
                                      commentsList[0].time,
                                    )}
                                  >
                                    <span>
                                      {getRelativeTimeString(
                                        fromUnixTime(commentsList[0].time),
                                      )}
                                    </span>
                                  </AtomicTooltip>
                                }
                                message={commentsList[0].comment}
                              />
                              <div className="flex h-10 w-8 flex-none items-start justify-end pt-1.5"></div>
                            </div>
                            <SeeAllButton
                              text={getSeeMoreCommentsText(
                                seeAllMessages,
                                commentsList.length - 3,
                              )}
                              onClick={() => setSeeAllMessages(!seeAllMessages)}
                            />

                            {(seeAllMessages
                              ? commentsList.slice(1)
                              : commentsList.slice(-2)
                            ).map((item, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="flex w-full items-start justify-end px-1 py-1"
                                >
                                  <ChatList.ChatListItem
                                    key={idx}
                                    name={item.user.name}
                                    avatar={
                                      <Avatar image={item.user.picture} />
                                    }
                                    time={
                                      <AtomicTooltip
                                        tooltipContent={getHumanReadableDateAndTime(
                                          item.time,
                                        )}
                                      >
                                        <span>
                                          {getRelativeTimeString(
                                            fromUnixTime(item.time),
                                          )}
                                        </span>
                                      </AtomicTooltip>
                                    }
                                    message={item.comment}
                                  />
                                  <div className="flex h-10 w-8 flex-none items-start justify-end pt-1.5">
                                    {props.commentThreadId && (
                                      <DeleteCommentButton
                                        onClick={deleteComment}
                                        isDeleting={isDeleting}
                                        hideButton={
                                          seeAllMessages
                                            ? idx !== commentsList?.length - 2
                                            : idx != 1
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {commentsList?.length > 0 &&
                              commentsList.map((item, idx) => {
                                return (
                                  <div
                                    key={idx}
                                    className="flex w-full items-start justify-end px-1 py-1"
                                  >
                                    <ChatList.ChatListItem
                                      key={idx}
                                      name={item.user.name}
                                      avatar={
                                        <Avatar image={item.user.picture} />
                                      }
                                      time={
                                        <AtomicTooltip
                                          tooltipContent={getHumanReadableDateAndTime(
                                            item.time,
                                          )}
                                        >
                                          <span>
                                            {getRelativeTimeString(
                                              fromUnixTime(item.time),
                                            )}
                                          </span>
                                        </AtomicTooltip>
                                      }
                                      message={item.comment}
                                    />
                                    <div className="flex h-10 w-8 flex-none items-start justify-end pt-1.5">
                                      {props.commentThreadId && (
                                        <DeleteCommentButton
                                          onClick={deleteComment}
                                          isDeleting={isDeleting}
                                          hideButton={
                                            idx !== commentsList?.length - 1
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </ChatList>
                    </div>
                  </div>
                </>
              ) : (
                <Loader />
              )}
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(postComment)}
                style={{ display: 'contents' }}
              >
                <div className="flex w-full flex-col items-start gap-4">
                  <div className="flex w-full flex-col items-end gap-2 py-4">
                    <div className="flex w-full items-start gap-2">
                      <Avatar image={user.profilePicture} />
                      <TextArea
                        className="h-auto grow shrink-0 basis-0"
                        helpText={errors.comment?.message}
                        error={methods.getFieldState('comment').invalid}
                      >
                        <TextArea.Input
                          placeholder="Post comment"
                          {...methods.register('comment')}
                          onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                              event.preventDefault();
                              handleSubmit(postComment)();
                            }
                          }}
                          disabled={!!disableActionsString || isSubmitting}
                        />
                      </TextArea>
                    </div>
                    {disableActionsString ? (
                      <AtomicTooltip
                        tooltipContent={`Action not allowed. ${disableActionsString}`}
                      >
                        <Button variant="neutral-secondary" disabled={true}>
                          Comment
                        </Button>
                      </AtomicTooltip>
                    ) : (
                      <Button
                        variant="neutral-secondary"
                        disabled={!!disableActionsString || isSubmitting}
                        loading={isSubmitting}
                        onClick={handleSubmit(postComment)}
                      >
                        Comment
                      </Button>
                    )}
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

const DeleteCommentButton = ({
  isDeleting,
  onClick,
  hideButton,
}: {
  isDeleting: boolean;
  onClick: () => void;
  hideButton: boolean;
}) => {
  return (
    <AtomicTooltip tooltipContent="Delete the last comment">
      <IconButton
        className={hideButton ? 'hidden' : ''}
        icon="FeatherTrash"
        loading={isDeleting}
        disabled={isDeleting}
        onClick={onClick}
      />
    </AtomicTooltip>
  );
};
