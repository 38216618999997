import Page from 'components/Page';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import {
  createUpgradePlan,
  useListSubscriptions,
  useListUpgradeTemplates,
} from 'api/frontend';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Breadcrumbs, Button, Loader } from 'subframe/index';
import { RadioGroup } from 'subframe/components/RadioGroup';
import { RouterLink } from 'components/RouterLink';
import useUserAccountState from 'hooks/useUserAccountState';
import { useAddonIdToNameMap } from 'hooks/useAddonIdToNameMap';
import { InfoTooltip } from 'subframe/index';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Stack } from 'components/utils';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import {
  useListAddonInstancesInfinite,
  useListClustersInfinite,
} from 'api/frontend-infinite';
import { clusterFlattenPages, flattenPages } from 'utils/arrays';
import { LockingWrapper } from 'components/LockingWrapper';
import { Searchbar } from 'components/design-system/Searchbar';
import { useDebounce } from 'react-use';
import BaseTable from 'components/design-system/Table/BaseTable';
import { filterUpgradeTemplatesByResources } from 'utils/upgrades';
import {
  AddonInstance,
  Cluster,
  ResolvedEntitlementFeaturesItem,
  UpgradePlan,
  UpgradeTemplate,
} from 'api/models';
import { extractMajorMinor } from 'utils/extractMajorMinor';
import MarkUpgradeTemplateAsApproved from '../components/MarkUpgradeTemplateAsApproved';

export default function UpgradesAddonCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logEvent } = AnalyticsEventLogger();
  const { account, currentOrganization } = useUserAccountState();
  const [searchParams] = useSearchParams();
  const addonsUpgradesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/plans/addons',
  );
  const addonUpgradeTemplatesBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/upgrades/templates/addons',
  );
  // Subscriptions
  const { data: Subscriptions } = useListSubscriptions(
    currentOrganization.slug,
    {},
    {
      request: { headers: { Authorization: `Bearer ${account.token}` } },
    },
  );
  // by default show lock is true
  const showLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  const showTemplateLock = Subscriptions
    ? !Subscriptions?.total_entitlement?.features?.includes(
        'request_upgrade_plan',
      )
    : true;

  // Upgrade Templates
  const { data: upgradeTemplatesData, mutate } = useListUpgradeTemplates(
    {},
    {
      request: {
        headers: { Authorization: `Bearer ${account.token}` },
      },
    },
  );
  const upgradeTemplates = upgradeTemplatesData
    ? filterUpgradeTemplatesByResources(
        upgradeTemplatesData.data,
      ).addonUpgradeTemplates.sort((a, b) => {
        return a.created > b.created ? -1 : 1; // Latest requested Upgrade Template should appear on top
      })
    : [];
  let templatesToCreateUpgradePlan: UpgradeTemplate[] = [];
  if (upgradeTemplatesData) {
    const availableTemplates = upgradeTemplates.filter((t) =>
      ['in-progress', 'update-in-progress', 'outdated', 'approved'].includes(
        t.status,
      ),
    );
    if (searchParams.get('template_id')) {
      templatesToCreateUpgradePlan = availableTemplates.filter(
        (t) => t.id === searchParams.get('template_id'),
      );
    } else {
      templatesToCreateUpgradePlan = availableTemplates;
    }
  }
  const [selectedTemplate, setSelectedTemplate] = useState<UpgradeTemplate>(
    searchParams.get('template_id')
      ? upgradeTemplates.filter(
          (t) => t.id === searchParams.get('template_id'),
        )[0]
      : ({} as UpgradeTemplate),
  );

  const [searchTemplate, setSearchTemplate] = useState('');
  const templatesToShow = useMemo(() => {
    if (templatesToCreateUpgradePlan) {
      return searchTemplate
        ? templatesToCreateUpgradePlan.filter((item) =>
            item?.name.toLowerCase().includes(searchTemplate.toLowerCase()),
          )
        : templatesToCreateUpgradePlan;
    }
  }, [searchTemplate, upgradeTemplatesData]);
  useDebounce(
    () => {
      if (searchTemplate) {
        logEvent('search-upgrade-templates', { searchTemplate });
      }
    },
    1000,
    [searchTemplate],
  );

  const handleOnSearchTemplateChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchTemplate(newValue);
  };

  const { data: clustersList, mutate: mutateClusters } =
    useListClustersInfinite(
      '',
      { status: 'active' },
      {
        request: { headers: { Authorization: `Bearer ${account?.token}` } },
      },
      {
        initialSize: 100,
        revalidateFirstPage: false,
      },
    );
  const clusters = clusterFlattenPages(clustersList || []) || [];

  const realClusters = clusters
    ? clusters.filter((c) => c.internal_k8s_ref !== 'example-cluster-ref')
    : [];

  const clusterIdToDetailsMap: Map<string, Cluster> = new Map(
    realClusters.map((c) => [c.id, c]),
  );

  const [searchCluster, setSearchCluster] = useState('');
  const handleOnChangeCluster = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchCluster(newValue);
  };

  useDebounce(
    () => {
      if (searchCluster) {
        logEvent('search-cluster', { searchCluster });
      }
    },
    1000,
    [searchCluster],
  );

  //  Add-ons
  const addonIdToNameMap = useAddonIdToNameMap({ token: account.token });
  const [selectedAddonInstance, setSelectedAddonInstance] = useState<
    AddonInstance & {
      addon_name: string;
      cluster_name: string;
    }
  >();

  const { data: addonInstancesData, isLoading: addonInstancesLoading } =
    useListAddonInstancesInfinite(
      {
        filter: ['status:active'],
      },
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
      },
    );
  const addonInstances = flattenPages(addonInstancesData || []);

  const addonsTableData = useMemo(() => {
    const addonInstancesWithDetails =
      addonInstances && addonIdToNameMap.size
        ? addonInstances.map((instance) => ({
            ...instance,
            addon_name: addonIdToNameMap.get(instance.addon_id) || '',
            cluster_name:
              clusterIdToDetailsMap.get(instance.cluster_id)?.name || '',
          }))
        : [];
    return addonInstancesWithDetails;
  }, [addonInstances, addonIdToNameMap, clustersList, upgradeTemplatesData]);

  const addonsToShow = useMemo(() => {
    const matchingAddonInstances = selectedTemplate?.id
      ? addonsTableData?.filter(
          (instance) =>
            extractMajorMinor(selectedTemplate?.current_version || '') ===
            extractMajorMinor(instance.primary_component?.image?.tag || ''),
        )
      : addonsTableData;
    return searchCluster
      ? matchingAddonInstances?.filter((item) =>
          item?.cluster_name
            .toLowerCase()
            .includes(searchCluster.toLowerCase()),
        )
      : matchingAddonInstances;
  }, [
    addonInstancesData,
    clustersList,
    searchCluster,
    selectedTemplate,
    addonsTableData.length,
  ]);

  // Upgrade Plan
  const [loading, setLoading] = useState(false);
  const submitUpgradePlan = async function () {
    if (loading) {
      return;
    }
    if (!selectedAddonInstance?.id) {
      enqueueSnackbar(
        'Please select an Add-on Instance to create an Upgrade Plan.',
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      return;
    }

    logEvent('upgrades-addons-upgrade-plan-selected', {
      upgrade_plan_type: selectedTemplate?.deployment_strategy,
    });

    setLoading(true);

    if (selectedTemplate?.status !== 'approved') {
      enqueueSnackbar(
        'Upgrade Template is not Approved for instantiating Upgrade Plans.',
        { variant: 'error', autoHideDuration: toastAutoHideDuration },
      );
      setLoading(false);
      return;
    }

    try {
      const createdUpgradePlan: UpgradePlan = await createUpgradePlan(
        {
          upgrade_template_id: selectedTemplate?.id,
          deployment_strategy:
            selectedTemplate?.deployment_strategy || 'pick-for-me',
          resources: {
            addon_instances: [{ id: selectedAddonInstance.id }],
          },
          name: `plan-${selectedAddonInstance.addon_name}-${selectedTemplate?.current_version}-${selectedTemplate?.desired_version}`,
        },
        { headers: { Authorization: `Bearer ${account.token}` } },
      );
      logEvent('upgrades-clusters-upgrade-plan-created', {
        upgrade_plan_type: selectedTemplate?.deployment_strategy,
        clusterId: selectedAddonInstance.cluster_id,
        upgradePlanId: createdUpgradePlan?.id,
        upgradePlanName: createdUpgradePlan?.name,
      });
      enqueueSnackbar(
        "Upgrade Plan request successful. We'll notify you when it is ready. Note that this process may take 24-48 hours in case there's a drift",
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      navigate(addonsUpgradesBasePath);
    } catch (err) {
      enqueueSnackbar(
        err.response?.data?.message ||
          somethingWentWrong.replace(
            '<action>',
            'requesting this Upgrade Plan',
          ),

        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(err);
    }
    setLoading(false);
  };

  return (
    <Page title="Add-on Upgrade Templates">
      <div className="flex h-full flex-col items-start gap-6 bg-default-background px-3 pt-8 ml-8 w-[calc(100% - 32px]">
        <Breadcrumbs>
          <Breadcrumbs.Item>Upgrade Copilot</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item>Upgrade Plans</Breadcrumbs.Item>
          <Breadcrumbs.Divider />
          <RouterLink to={addonsUpgradesBasePath}>
            <Breadcrumbs.Item>Add-ons</Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true}>Instantiate new </Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="flex w-full flex-col items-start gap-2">
          <span className="text-section-header font-section-header text-default-font">
            Instantiate Add-on Upgrade Plan
          </span>
          <span className="text-body font-body text-subtext-color">
            Create an Upgrade Plan from a Template
          </span>
        </div>

        {/* Pick a template */}

        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-subheader font-subheader text-default-font">
              Pick a Template
            </span>
            <InfoTooltip tooltipText="An Upgrade Plan will be created using the template you select. Image Tag of the selected add-on  must match the Upgrade Template's 'From' version. " />
          </div>
          {templatesToCreateUpgradePlan.length &&
          !searchParams.get('template_id') ? (
            <Searchbar onChange={handleOnSearchTemplateChange} />
          ) : (
            <></>
          )}
        </div>

        {upgradeTemplatesData === undefined ||
        addonInstances == undefined ||
        !addonsTableData.length ||
        !addonIdToNameMap.size ? (
          <div className="flex items-center gap-2">
            <span className="text-body font-body text-default-font">
              Fetching upgrade templates
            </span>
            <Loader />
          </div>
        ) : (
          <>
            {templatesToCreateUpgradePlan.length ? (
              <BaseTable
                settings={{
                  localStorageKey: 'upgrades_addons_create_pick_template',
                  dataCyPrefix: 'upgrades-addons-create-pick-template',
                }}
                data={templatesToShow || []}
                noMatchingData={
                  !!templatesToCreateUpgradePlan?.length &&
                  !templatesToShow?.length
                }
                onRowClick={(template) => {
                  setSelectedTemplate(template);
                }}
                columns={[
                  {
                    id: 'template',
                    title: 'Upgrade Template',
                    titleStyle: { width: '40%' },
                    cellType: 'cell',
                    render: (template) => {
                      return (
                        <>
                          <RadioGroup>
                            <RadioGroup.Option
                              checked={selectedTemplate?.id === template.id}
                              label={template.name}
                              value={template.id}
                              className="text-left"
                            />
                          </RadioGroup>
                          {template.status !== 'approved' ? (
                            <MarkUpgradeTemplateAsApproved
                              upgradeId={template.id}
                              mutateTemplate={mutate}
                              tooltipText="Mark Upgrade Template as Approved. Only approved Templates can be used to Instantiate Upgrade Plans."
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    },
                  },
                  {
                    id: 'add-on',
                    title: 'Add-on',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: (template) => {
                      const templateAddonInstanceId = template.resources
                        .addon_instances?.length
                        ? template.resources.addon_instances[0].id
                        : '';
                      const templateAddonDetails = addonsTableData.find(
                        (ai) => {
                          return ai.id === templateAddonInstanceId;
                        },
                      );
                      return (
                        <span className="whitespace-nowrap text-body font-body text-subtext-color">
                          {templateAddonDetails?.addon_name}
                        </span>
                      );
                    },
                  },
                  {
                    id: 'from',
                    title: 'From',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: (template) => {
                      return (
                        <span className="whitespace-nowrap text-body font-body text-subtext-color">
                          {template.current_version}
                        </span>
                      );
                    },
                  },
                  {
                    id: 'to',
                    title: 'To',
                    titleStyle: { width: '20%' },
                    cellType: 'cell',
                    render: (template) => {
                      return (
                        <span className="whitespace-nowrap text-body font-body text-subtext-color">
                          {template.desired_version}
                        </span>
                      );
                    },
                  },
                ]}
                actions={[]}
              />
            ) : (
              <div className="flex items-center gap-2">
                <span className="text-body font-body text-default-font">
                  {upgradeTemplates.length
                    ? 'No Upgrade Templates available to instantiate Add-on Upgrade Plan.'
                    : 'No Upgrade Templates requested.'}
                </span>
                <RouterLink to={`${addonUpgradeTemplatesBasePath}/new`}>
                  <LockingWrapper
                    upgradePlanType={'cluster'}
                    feature={
                      ResolvedEntitlementFeaturesItem.request_upgrade_template
                    }
                    showLock={showTemplateLock}
                  >
                    <Button
                      disabled={false}
                      size="medium"
                      icon="FeatherPlus"
                      iconRight={showTemplateLock ? 'FeatherLock' : null}
                    >
                      Request Add-on Upgrade Template
                    </Button>
                  </LockingWrapper>
                </RouterLink>
              </div>
            )}
          </>
        )}

        {/* Pick add-on instance */}

        <div className="flex w-full flex-col items-start gap-6">
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-subheader font-subheader text-default-font">
                Select Add-on Instance to Upgrade
              </span>
              <InfoTooltip tooltipText="An Upgrade Template can be used to create Upgrade Plans for matching add-on instances across fleet." />
            </div>
            {addonsTableData.length && clusters && selectedTemplate?.id ? (
              <Searchbar
                onChange={handleOnChangeCluster}
                placeholderText="Search Cluster"
              />
            ) : (
              <></>
            )}
          </div>
          {!selectedTemplate?.id && (
            <span className="text-body font-body text-default-font">
              Add-on instances will be populated when you select an Upgrade
              Template.
            </span>
          )}
          {selectedTemplate?.id && !addonsToShow && (
            <div className="flex items-center gap-2">
              <span className="text-body font-body text-default-font">
                Fetching addon instances
                {selectedTemplate?.current_version}
              </span>
              <Loader />
            </div>
          )}
          {selectedTemplate?.id && addonsToShow && (
            <BaseTable
              settings={{
                localStorageKey: 'upgrades_addons_create_pick_addon_instance',
                dataCyPrefix: 'upgrades-addons-create-pick-addon-instance',
              }}
              data={addonsToShow || []}
              noMatchingData={!addonsToShow}
              onRowClick={(addon_instance) => {
                logEvent('upgrades-addon-instance-selected', {
                  addon_instance_id: addon_instance.id,
                  cluster_id: addon_instance.cluster_id,
                });
                setSelectedAddonInstance(addon_instance);
              }}
              columns={[
                {
                  id: 'addon',
                  title: 'Add-on',
                  titleStyle: { width: '40%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <RadioGroup
                        className="max-w-[256px] whitespace-nowrap overflow-hidden overflow-ellipsis"
                        label=""
                        helpText=""
                        horizontal={true}
                        value=""
                      >
                        <RadioGroup.Option
                          checked={
                            selectedAddonInstance?.id === addonInstance.id
                          }
                          label={addonInstance.addon_name}
                          value={addonInstance.id}
                        />
                      </RadioGroup>
                    );
                  },
                },
                {
                  id: 'tag',
                  title: (
                    <Stack direction="row" spacing={1}>
                      Tag
                      <InfoTooltip tooltipText="The Tags that have been locally applied to an Add-on's container images" />
                    </Stack>
                  ),
                  titleStyle: { width: '30%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <span className="whitespace-nowrap text-body font-body text-subtext-color">
                        {addonInstance.primary_component?.image?.tag}
                      </span>
                    );
                  },
                },
                {
                  id: 'cluster',
                  title: 'Cluster',
                  titleStyle: { width: '30%' },
                  cellType: 'cell',
                  render: (addonInstance) => {
                    return (
                      <span className="text-body font-body text-subtext-color">
                        {addonInstance.cluster_name}
                      </span>
                    );
                  },
                },
              ]}
              actions={[]}
            />
          )}

          {/* Create Plan */}
          {selectedAddonInstance?.id && selectedTemplate?.id ? (
            <LockingWrapper
              upgradePlanType={'addon'}
              feature={ResolvedEntitlementFeaturesItem.request_upgrade_plan}
              showLock={showLock}
              defaultAction={() => submitUpgradePlan()}
              tooltipText={
                showLock
                  ? undefined
                  : 'Instantiate Upgrade Plan using Upgrade Template'
              }
            >
              <Button
                loading={loading}
                disabled={loading}
                icon="FeatherPlus"
                iconRight={showLock ? 'FeatherLock' : null}
              >
                Instantiate Upgrade Plan
              </Button>
            </LockingWrapper>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Page>
  );
}
