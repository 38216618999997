import { NavListProps } from 'components/nav-section/type';
import Iconify from 'src/components/Iconify';
import { useGate } from 'statsig-react';
import logo from 'src/assets/images/SHARC_logo.svg';
import { Avatar } from 'subframe/index';

export default function NavConfig(): {
  subheader: string;
  items: NavListProps[];
}[] {
  const flippedArsigViewSidenavTab = useGate('flipped_arsig_view_sidenav_tab');
  const showSHARC = useGate('show_sharc');

  // Risk Ledger
  const riskLedgerNavItems = [];
  if (flippedArsigViewSidenavTab.value) {
    riskLedgerNavItems.push({
      id: 'availabilityrisks-sidenav-tab',
      title: 'Fleet',
      path: '/orgs/:orgId/accounts/:accountId/availability-risks',
      icon: <Iconify icon={'material-symbols:warning-rounded'} />,
      trackerEvent: 'availabilityrisks-sidenav',
    });
  }
  riskLedgerNavItems.push({
    id: 'clusters-sidenav-tab',
    title: 'Clusters',
    path: '/orgs/:orgId/accounts/:accountId/clusters',
    icon: <Iconify icon={'ph:aperture'} />,
    trackerEvent: 'clusters-sidenav',
  });

  // Artifact Register
  const artifactRegisterNavItems = [
    {
      id: 'ar-controlplane-sidenav-tab',
      title: 'Control Plane',
      path: '/orgs/:orgId/accounts/:accountId/artifact_register/control_plane',
      icon: <Iconify icon={'ph:aperture'} />,
      trackerEvent: 'infra-clusters-sidenav',
    },
    {
      id: 'vr-addons-sidenav-tab',
      title: 'Add-ons',
      path: '/orgs/:orgId/accounts/:accountId/artifact_register/addons',
      icon: <Iconify icon={'iconoir:3d-select-solid'} />,
      trackerEvent: 'infra-addons',
    },
  ];

  // Upgrade Copilot
  const upgradeCopilotNavItems = [
    {
      id: 'upgrade-plans-sidenav-tab',
      title: 'Upgrade Plans',
      icon: <Iconify icon={'clarity:layers-line'} />,
      children: [
        {
          id: 'upgrades-clusters-sidenav-tab',
          title: 'Clusters',
          path: '/orgs/:orgId/accounts/:accountId/upgrades/plans/clusters',
          icon: <Iconify icon={'ph:aperture'} />,
          trackerEvent: 'upgrades-clusters',
        },
        {
          id: 'upgrades-addons-sidenav-tab',
          title: 'Add-ons',
          path: '/orgs/:orgId/accounts/:accountId/upgrades/plans/addons',
          icon: <Iconify icon={'iconoir:3d-select-solid'} />,
          trackerEvent: 'upgrades-addons',
        },
      ],
    },
    {
      id: 'upgrade-templates-sidenav-tab',
      title: 'Upgrade Templates',
      icon: <Iconify icon={'lucide:layout-template'} />,
      children: [
        {
          id: 'upgrades-templates-clusters-sidenav-tab',
          title: 'Clusters',
          path: '/orgs/:orgId/accounts/:accountId/upgrades/templates/clusters',
          icon: <Iconify icon={'ph:aperture'} />,
          trackerEvent: 'upgrades-templates-clusters',
        },
        {
          id: 'upgrades-templates-addons-sidenav-tab',
          title: 'Add-ons',
          path: '/orgs/:orgId/accounts/:accountId/upgrades/templates/addons',
          icon: <Iconify icon={'iconoir:3d-select-solid'} />,
          trackerEvent: 'upgrades-templates-addons',
        },
      ],
    },
  ];

  // Configure
  const configureNavItems = [
    {
      id: 'teams-sidenav-tab',
      title: 'Teams',
      path: '/orgs/:orgId/accounts/:accountId/teams',
      icon: <Iconify icon={'akar-icons:people-group'} />,
      trackerEvent: 'teams-sidenav',
    },
    {
      id: 'cloudaccounts-sidenav-tab',
      title: 'Cloud Accounts',
      path: '/orgs/:orgId/accounts/:accountId/cloud_accounts',
      icon: <Iconify icon={'material-symbols:cloud-outline'} />,
      trackerEvent: 'cloudaccounts-sidenav',
    },
    {
      id: 'integrations-sidenav-tab',
      title: 'Integrations',
      path: '/orgs/:orgId/accounts/:accountId/integrations',
      icon: <Iconify icon={'bxs:network-chart'} />,
      trackerEvent: 'integrations-sidenav',
    },
    {
      id: 'settings-sidenav-tab',
      title: 'Settings',
      path: '/orgs/:orgId/accounts/:accountId/settings',
      icon: (
        <Iconify
          icon="heroicons-outline:cog"
          sx={{ transform: 'rotate(90deg) scaleY(-1)' }}
        />
      ),
      trackerEvent: 'settings-sidenav',
    },
    {
      id: 'billings-sidenav-tab',
      title: 'Billing & Subscriptions',
      path: '/orgs/:orgId/subscriptions',
      icon: <Iconify icon="fontisto:wallet" />,
      trackerEvent: 'settings-sidenav',
    },
  ];

  // Explore
  const exploreNavItems = [
    {
      title: 'Concepts',
      id: 'concepts-sidenav-tab',
      path: '/orgs/:orgId/accounts/:accountId/concepts',
      icon: <Iconify icon={'mdi:lightbulb-variant-outline'} />,
      trackerEvent: 'concepts-sidenav',
    },
    {
      title: 'Security',
      id: 'security-sidenav-tab',
      path: '/orgs/:orgId/accounts/:accountId/security',
      icon: <Iconify icon={'mdi:security-lock-outline'} />,
      trackerEvent: 'security-sidenav',
    },
    {
      title: 'Clouds & Add-ons',
      id: 'clouds-addons-sidenav-tab',
      path: '/orgs/:orgId/accounts/:accountId/explore/supported',
      icon: <Iconify icon={'lucide:cloudy'} />,
      trackerEvent: 'explore-supported',
    },
    {
      title: 'Troubleshooting',
      id: 'troubleshoot-sidenav-tab',
      path: '/orgs/:orgId/accounts/:accountId/troubleshoot-k8s-connector',
      icon: <Iconify icon={'mdi:lightbulb-variant-outline'} />,
      trackerEvent: 'troubleshoot-k8s-connector',
    },
  ];
  //  Feature Flags Handling
  if (showSHARC.value) {
    exploreNavItems.push({
      title: 'SHARC',
      id: 'sharc-sidenav-tab',
      path: '/orgs/:orgId/accounts/:accountId/sharc',
      icon: (
        <Avatar
          image={logo}
          variant="brand"
          size="large"
          square={false}
          className="custom-class bg-transparent"
        />
      ),
      trackerEvent: 'sharc-sidenav',
    });
  }

  //Left Menu Navigation Config
  const config = [
    {
      subheader: 'RISK LEDGER',
      items: riskLedgerNavItems,
    },
    {
      subheader: 'ARTIFACT REGISTER',
      items: artifactRegisterNavItems,
    },
    {
      subheader: 'UPGRADE COPILOT',
      items: upgradeCopilotNavItems,
    },
    {
      subheader: 'CONFIGURE',
      items: configureNavItems,
    },
    {
      subheader: 'EXPLORE',
      items: exploreNavItems,
    },
  ];
  return config;
}
