'use client';

import React, { useEffect, useState } from 'react';
import { TextField } from 'subframe/components/TextField';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Yup from 'yup';
import { fieldIsRequiredErrorMessage } from 'constants/input-validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from 'api/axios-instance';
import * as Sentry from '@sentry/browser';
import FormProvider from 'components/FormProvider';
import { useSearchParams } from 'react-router-dom';
import { IconButton } from 'subframe/components/IconButton';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { IconWithBackground } from 'subframe/components/IconWithBackground';

interface SignupRequestFields {
  firstname: string;
  company: string;
  email: string;
  jobtitle: string;
  phone: string;
}

interface HubspotSignupRequestFormField {
  name: string;
  value: string;
}

interface HubspotSignupRequestPayload {
  portalId: string;
  formGuid: string;
  fields: HubspotSignupRequestFormField[];
}

function GCPMarketplaceSignup() {
  const { enqueueSnackbar } = useSnackbar();
  const HSPortalId = '21736838';
  const HSFormGuid = '0fd3d945-523f-4989-846f-f900b0b15036';
  const HSSubmissionUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${HSPortalId}/${HSFormGuid}`;
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [gcpError, setGcpError] = useState(false);
  const { logEvent } = AnalyticsEventLogger();

  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'submitting your signup request',
  );
  const FormSchema = Yup.object().shape({
    firstname: Yup.string().required(fieldIsRequiredErrorMessage('Name')),
    company: Yup.string().required(fieldIsRequiredErrorMessage('Company Name')),
    email: Yup.string()
      .required(fieldIsRequiredErrorMessage('Work Email'))
      .email('Email is invalid'),
    jobtitle: Yup.string().required(fieldIsRequiredErrorMessage('Job Title')),
    phone: Yup.string().optional(),
  });

  const defaultValues: SignupRequestFields = {
    firstname: '',
    company: '',
    email: '',
    jobtitle: '',
    phone: '',
  };

  const methods = useForm<SignupRequestFields>({
    resolver: yupResolver(FormSchema),
    defaultValues: defaultValues,
  });
  const [searchParams] = useSearchParams();

  const status = searchParams.get('status');
  const procurementId = searchParams.get('procurement_id');
  const gcp_gaia = searchParams.get('gaia');

  useEffect(() => {
    if (status) {
      if (status === 'success' && procurementId) {
        enqueueSnackbar(
          'Thank you for your purchase. Complete your signup by providing us with the following details',
          {
            variant: 'success',
          },
        );
      } else {
        setGcpError(true);
        enqueueSnackbar(
          'Something went wrong. We have recorded this failure to get back to you shortly',
          {
            variant: 'error',
          },
        );
        Sentry.captureException('GCP Marketplace Signup Error', {
          extra: { procurementId, gcp_gaia },
        });
      }
    }
  }, [status, procurementId]);

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const sendSignupRequest = async (data: SignupRequestFields) => {
    const fields: HubspotSignupRequestFormField[] = Object.entries(data).map(
      ([key, value]) => {
        return {
          name: key,
          value: value,
        };
      },
    );
    // Add gcp_procurement_id and gcp_gaia in the fields object
    fields.push({
      name: 'gcp_procurement_id',
      value: procurementId || '',
    });
    fields.push({
      name: 'gcp_gaia',
      value: gcp_gaia || '',
    });
    const reqBody: HubspotSignupRequestPayload = {
      portalId: HSPortalId,
      formGuid: HSFormGuid,
      fields: fields,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    // makes axios call to submit signup request using HSSubmissionUrl
    return await axiosInstance.post(HSSubmissionUrl, reqBody, { headers });
  };
  const onSubmit = async (data: SignupRequestFields) => {
    try {
      setLoading(true);
      setDisableSubmit(true);
      await sendSignupRequest(data);
      enqueueSnackbar(
        'You will receive a confirmation email from Chkk within 24 hours',
        {
          variant: 'success',
        },
      );
      logEvent('gcp-marketplace-signup-requested', data);
      setTimeout(() => {
        window.location.href = `https://chkk.io`;
      }, 2500);
    } catch (error) {
      setDisableSubmit(false);
      Sentry.captureException(error, { extra: { data } });
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!gcpError && (
        <Dialog open={true}>
          <Dialog.Content>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className="flex w-144 grow shrink-0 basis-0 flex-col items-start justify-center gap-4 px-4 py-4">
                <div className="flex w-full flex-col items-start gap-2">
                  <div className="flex w-full items-center gap-2">
                    <div className="flex grow shrink-0 basis-0 flex-col items-start gap-2">
                      <span className="w-full text-subheader font-subheader text-default-font">
                        Signup
                      </span>
                    </div>
                    <IconButton
                      icon="FeatherX"
                      size="medium"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        methods.reset();
                        window.location.href = `https://chkk.io`;
                      }}
                    />
                  </div>
                  <span className="text-body font-body text-subtext-color">
                    Provide the following information so we can process your
                    sign up request via GCP Marketplace.
                  </span>
                </div>
                <TextField
                  className="h-auto w-full flex-none"
                  label="Name"
                  error={methods.getFieldState('firstname').invalid}
                  helpText={errors.firstname?.message}
                >
                  <TextField.Input {...methods.register('firstname')} />
                </TextField>
                <TextField
                  className="h-auto w-full flex-none"
                  label="Company Name"
                  error={methods.getFieldState('company').invalid}
                  helpText={errors.company?.message}
                >
                  <TextField.Input {...methods.register('company')} />
                </TextField>
                <TextField
                  className="h-auto w-full flex-none"
                  label="Job Title"
                  error={methods.getFieldState('jobtitle').invalid}
                  helpText={errors.jobtitle?.message}
                >
                  <TextField.Input {...methods.register('jobtitle')} />
                </TextField>
                <TextField
                  className="h-auto w-full flex-none"
                  error={methods.getFieldState('email').invalid}
                  helpText={errors.email?.message}
                  label="Work Email"
                >
                  <TextField.Input {...methods.register('email')} />
                </TextField>
                <TextField
                  className="h-auto w-full flex-none"
                  error={methods.getFieldState('phone').invalid}
                  helpText={errors.phone?.message}
                  label="Phone"
                >
                  <TextField.Input {...methods.register('phone')} />
                </TextField>
                <div className="flex w-full grow shrink-0 basis-0 flex-col items-center gap-4 pr-3">
                  <Button
                    variant="brand-primary"
                    size="large"
                    disabled={disableSubmit || loading}
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </FormProvider>
          </Dialog.Content>
        </Dialog>
      )}
      {gcpError && (
        <div className="container max-w-none flex h-full w-full flex-col items-center justify-center gap-4 bg-default-background py-12">
          <div className="flex max-w-[320px] flex-col items-center justify-center gap-6">
            <IconWithBackground
              variant="error"
              size="large"
              icon="FeatherAlertTriangle"
            />
            <div className="flex flex-col items-center gap-2">
              <span className="text-section-header font-section-header text-default-font text-center">
                Something went wrong
              </span>
              <span className="text-body font-body text-subtext-color text-center">
                We have recorded this failure and will get in touch on your
                email to resolve it.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GCPMarketplaceSignup;
