import { UpgradePlan, UpgradeStage, UpgradeTemplate } from 'api/models';
import { ARGOCD_PLAN, ARGOCD_PLAN_STAGES } from './upgrades_addons_argocd';
import { ISTIO_PLAN, ISTIO_PLAN_STAGES } from './upgrades_addons_istio';
import {
  PROMETHEUS_PLAN,
  PROMETHEUS_PLAN_STAGES,
} from './upgrades_addons_prometheus';
import {
  GRAFANA_LOKI_PLAN,
  GRAFANA_LOKI_PLAN_STAGES,
} from './upgrades_addons_grafana_loki';
import {
  UPGRADE_PLAN_CLUSTERS_LULU,
  UPGRADE_PLAN_CLUSTERS_LULU_STAGES,
} from './upgrades_clusters_lululemon';
import {
  UPGRADE_PLAN_CLUSTERS_PROD_USWEST1,
  UPGRADE_PLAN_CLUSTERS_PROD_USWEST1_STAGES,
} from './upgrades_clusters_prod-uswest2';
import {
  UPGRADE_TEMPLATE_CLUSTERS_EKS_124,
  UPGRADE_TEMPLATE_CLUSTERS_EKS_124_STAGES,
} from './upgrades_templates_eks_124';
import {
  UPGRADE_TEMPLATE_ADDONS_ISTIO,
  UPGRADE_TEMPLATE_ADDONS_ISTIO_STAGES,
} from './upgrade_template_istio';

export const UPGRADE_TEMPLATES: UpgradeTemplate[] = [
  // Cluster Upgrade Templates
  UPGRADE_TEMPLATE_CLUSTERS_EKS_124,
  {
    id: 'upgtmpl_1',
    environment: 'Dev',
    status: 'approved',
    name: 'template-gke-127-usw4-dev-v1.27',
    current_version: 'v1.27',
    desired_version: 'v1.28',
    resources: { clusters: [{ id: 'id-gke-127-usw4-dev' }] },
    deployment_strategy: 'blue-green',
    created: new Date(2024, 0, 4).getTime(),
    updated: new Date(2024, 0, 5).getTime(),
    last_validated: new Date(2024, 0, 5).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    approved_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    status_comment:
      "The recommended upgrade steps look good, let's proceed with the upgrade.",
    approved: new Date(2024, 0, 7).getTime(),
  },
  {
    id: 'upgtmpl_2',
    environment: 'Dev',
    status: 'in-progress',
    name: 'template-gke-124-nane1-dev-v1.24',
    current_version: 'v1.24',
    desired_version: 'v1.25',
    resources: { clusters: [{ id: 'id-gke-124-nane1-dev' }] },
    deployment_strategy: 'in-place',
    created: new Date(2023, 5, 3).getTime(),
    updated: new Date(2023, 5, 8).getTime(),
    last_validated: new Date(2023, 5, 8).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
  {
    id: 'upgtmpl_3',
    name: 'template-gke-124-nane1-dev-v1.24',
    resources: { clusters: [{ id: 'id-gke-124-nane1-dev' }] },
    current_version: 'v1.24',
    desired_version: 'v1.25',
    deployment_strategy: 'in-place',
    environment: 'Dev',
    status: 'in-progress',
    created: new Date(2023, 5, 3).getTime(),
    updated: new Date(2023, 5, 7).getTime(),
    last_validated: new Date(2023, 5, 7).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
  {
    id: 'upgtmpl_4',
    name: 'template-aks-126-eu2-staging-v1.26',
    resources: { clusters: [{ id: 'id-aks-126-eu2-staging' }] },
    current_version: 'v1.26',
    desired_version: 'v1.27',
    deployment_strategy: 'in-place',
    environment: 'Staging',
    status: 'approved',
    created: new Date(2023, 5, 5).getTime(),
    updated: new Date(2023, 5, 10).getTime(),
    last_validated: new Date(2023, 5, 10).getTime(),
    requested_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
    approved_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
    status_comment:
      "The recommended upgrade steps look good, let's proceed with the upgrade.",
    approved: new Date(2024, 5, 11).getTime(),
  },

  // Add-on Upgrade Templates
  UPGRADE_TEMPLATE_ADDONS_ISTIO,
  {
    id: 'upgtmpl_5',
    name: 'template-grafana-loki-v2.6.1',
    resources: {
      addon_instances: [
        {
          id: 'id-grafana-loki-upgrade-1',
        },
      ],
    },
    status: 'in-progress',
    current_version: 'v2.6.1',
    desired_version: 'v2.8.3',
    deployment_strategy: 'in-place',
    environment: 'Staging',
    created: new Date(2024, 1, 1).getTime(),
    updated: new Date(2024, 1, 8).getTime(),
    last_validated: new Date(2024, 1, 6).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
  {
    id: 'upgtmpl_6',
    name: 'template-argocd-v2.5.1',
    resources: {
      addon_instances: [
        {
          id: 'id-argocd_upgrade-1',
        },
      ],
    },
    status: 'approved',
    current_version: 'v2.5.1',
    desired_version: 'v2.5.16',
    deployment_strategy: 'in-place',
    environment: 'Prod',
    created: new Date(2023, 11, 25).getTime(),
    updated: new Date(2024, 0, 21).getTime(),
    last_validated: new Date(2024, 0, 19).getTime(),
    requested_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
    approved_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
  {
    id: 'upgtmpl_7',
    name: 'template-prometheus-v0.63.0',
    resources: {
      addon_instances: [
        {
          id: 'id-prometheus-operator-upgrade-1',
        },
      ],
    },
    status: 'regenerate-in-progress',
    current_version: 'v0.63.0',
    desired_version: 'v0.66.0',
    deployment_strategy: 'in-place',
    environment: 'Dev',
    created: new Date(2023, 8, 27).getTime(),
    updated: new Date(2023, 9, 14).getTime(),
    last_validated: new Date(2023, 9, 12).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
  {
    id: 'upgtmpl_8',
    name: 'template-nginx-oss-v1.14.2',
    resources: {
      addon_instances: [
        {
          id: 'id-nginx-oss-upgrade-1',
        },
      ],
    },
    status: 'approved',
    current_version: 'v1.14.2',
    desired_version: 'v1.25.3',
    deployment_strategy: 'in-place',
    environment: 'Dev',
    created: new Date(2023, 8, 27).getTime(),
    updated: new Date(2023, 9, 14).getTime(),
    last_validated: new Date(2023, 9, 12).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    approved_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
  },
];

export const UPGRADES: UpgradePlan[] = [
  /* Cluster Upgrades */
  UPGRADE_PLAN_CLUSTERS_PROD_USWEST1,
  {
    id: 'upgr_1',
    name: 'plan-eks-125-euc1-staging-v1.25-v1.26',
    resources: {
      clusters: [{ id: 'id-eks-125-euc1-staging' }],
    },
    deployment_strategy: 'blue-green',
    status: 'completed',
    created: new Date(2024, 0, 12).getTime(),
    updated: new Date(2024, 0, 18).getTime(),
    last_validated: new Date(2024, 0, 17).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    completed_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
  },
  {
    id: 'upgr_2',
    name: 'plan-gke-127-usw4-dev-v1.27-v1.28',
    resources: { clusters: [{ id: 'id-gke-127-usw4-dev' }] },
    deployment_strategy: 'blue-green',
    status: 'completed',
    created: new Date(2024, 0, 6).getTime(),
    updated: new Date(2024, 0, 8).getTime(),
    last_validated: new Date(2024, 0, 7).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    completed_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
  },
  {
    id: 'upgr_3',
    name: 'plan-gke-124-nane1-dev-v1.24-v1.25',
    resources: { clusters: [{ id: 'id-gke-124-nane1-dev' }] },
    deployment_strategy: 'in-place',
    status: 'completed',
    created: new Date(2023, 5, 8).getTime(),
    updated: new Date(2023, 5, 10).getTime(),
    last_validated: new Date(2023, 5, 9).getTime(),
    requested_by: {
      email: 'roger@chkk.io',
      id: '',
      name: 'Roger Walter',
      picture: '',
    },
    completed_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
  },
  {
    id: 'upgr_4',
    name: 'plan-aks-126-eu2-staging-v1.26-v1.27',
    resources: { clusters: [{ id: 'id-aks-126-eu2-staging' }] },
    deployment_strategy: 'in-place',
    status: 'completed',
    created: new Date(2023, 5, 12).getTime(),
    updated: new Date(2023, 5, 18).getTime(),
    last_validated: new Date(2023, 5, 15).getTime(),
    requested_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
    completed_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
  },

  /* Addon Upgrades */
  ISTIO_PLAN,
  PROMETHEUS_PLAN,
  GRAFANA_LOKI_PLAN,
  ARGOCD_PLAN,
  {
    id: 'upgr_3',
    name: 'plan-nginx-v1.14.2-v1.25.3',
    resources: {
      addon_instances: [
        {
          id: 'id-nginx-oss-upgrade-1',
        },
      ],
    },
    status: 'completed',
    deployment_strategy: 'in-place',
    created: new Date(2024, 1, 5).getTime(),
    updated: new Date(2024, 1, 9).getTime(),
    last_validated: new Date(2024, 1, 8).getTime(),
    requested_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
    completed_by: {
      email: 'katy@chkk.io',
      id: '',
      name: 'Katy Smith',
      picture: '',
    },
  },
];

export const UPGRADE_STAGES = new Map<string, UpgradeStage[]>([
  [UPGRADE_TEMPLATE_ADDONS_ISTIO.id, UPGRADE_TEMPLATE_ADDONS_ISTIO_STAGES],
  [
    UPGRADE_TEMPLATE_CLUSTERS_EKS_124.id,
    UPGRADE_TEMPLATE_CLUSTERS_EKS_124_STAGES,
  ],
  [ARGOCD_PLAN.id, ARGOCD_PLAN_STAGES],
  [
    UPGRADE_PLAN_CLUSTERS_PROD_USWEST1.id,
    UPGRADE_PLAN_CLUSTERS_PROD_USWEST1_STAGES,
  ],
  [UPGRADE_PLAN_CLUSTERS_LULU.id, UPGRADE_PLAN_CLUSTERS_LULU_STAGES],
  [ISTIO_PLAN.id, ISTIO_PLAN_STAGES],
  [PROMETHEUS_PLAN.id, PROMETHEUS_PLAN_STAGES],
  [GRAFANA_LOKI_PLAN.id, GRAFANA_LOKI_PLAN_STAGES],
]);
