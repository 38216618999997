import * as Sentry from '@sentry/browser';
import { getUsers, login as loginApi } from 'api/frontend';
import {
  Organization,
  TeamInvitation,
  LoginResponse as loginApiResponse,
} from 'api/models';
import { toastAutoHideDuration } from 'constants/toasts';
import useSessionStorage from 'hooks/useSessionStorage';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { makeAuth0JsClient } from 'pages/auth/Login';
import { generateRandomString } from 'pages/auth/pkceUtils';
import { useSWRConfig } from 'swr';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { indexedDBUtil, TokenData } from 'utils/indexDB';

export default function TokenService() {
  const {
    tokens,
    user,
    loginType,
    organizations,
    updateUserAccountState,
    updateTokens,
  } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const { postHogIdentify, identify, logEvent } = AnalyticsEventLogger();
  const { mutate } = useSWRConfig();
  const auth0JS = makeAuth0JsClient();

  const [pkceNonce] = useSessionStorage<string>(
    'pkce-nonce',
    generateRandomString(16),
  );

  const getChkkUserToken = async (): Promise<string | TokenData> => {
    const tokenData = await indexedDBUtil.getToken();
    return tokenData?.idToken || '';
  };

  const setCurrentOrgLocalStorage = (newOrg: Organization) => {
    const stringifyOrg = JSON.stringify(newOrg);
    localStorage.setItem('currentOrganization', stringifyOrg);
  };
  const setPendingInvitationSessionStorage = (
    pending_invitations: TeamInvitation[],
  ) => {
    const stringifyPendingInvitations = JSON.stringify(pending_invitations);
    sessionStorage.setItem('pending_invitations', stringifyPendingInvitations);
  };

  const isAuthenticated = async (): Promise<boolean> => {
    const userToken = await indexedDBUtil.getToken();

    if (userToken?.idToken) {
      const validateTokenPromise = new Promise<boolean>((resolve) => {
        auth0JS.validateToken(
          userToken?.idToken,
          pkceNonce || '',
          (err, res) => {
            if (err) {
              resolve(false);
            } else {
              resolve(true);
            }
          },
        );
      });
      var tokenRefreshed;
      if (userToken?.refreshToken) {
        tokenRefreshed = await refreshToken();
      }
      if (
        (await tokenRefreshed) ||
        (await validateTokenPromise) ||
        window.Cypress
      ) {
        if (
          await loginWithUserToken(
            userToken?.idToken,
            userToken?.accessToken,
            userToken?.refreshToken,
            userToken?.expiry || 0,
          )
        ) {
          return true;
        }
      }
      await indexedDBUtil.removeToken();
    }
    return false;
  };

  const loginWithFederationToken = async (
    federationToken: string,
  ): Promise<boolean> => {
    try {
      // lookup details for the token
      const data = await getUsers({
        headers: { Authorization: `Bearer ${federationToken}` },
      });

      // to avoid confusion, logging the current user out
      await indexedDBUtil.removeToken();

      const currentOrg: Organization = {
        id: data.organization_id || '',
        slug: data.organization_slug || '',
        name: data.organization_id || '',
        created: 0,
        status: 'ACTIVE',
      };
      updateUserAccountState({
        loginType: 'federated',

        isAccountLoading: false,
        isCurrentOrgLoading: false,
        isOrganizationsLoading: false,

        user: {
          name: `Federated as ${data.name}`,
          userId: data.user_id || '',
          email: data.email,
          profilePicture: undefined,
        },
        account: {
          token: federationToken,
          accountId: data.account_id || '',
        },
        organizations: [currentOrg],
        tokens: {},
        currentOrganization: currentOrg,
        lastLoginTime: new Date().getTime() / 1000,
      });

      return true;
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  };

  const loginWithUserToken = async (
    idToken: string,
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
  ): Promise<boolean> => {
    try {
      // Store the token in IndexedDB
      await indexedDBUtil.setToken({
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiry: Date.now() + expiresIn * 1000,
      });
      // Set up a timer to refresh the token before it expires
      setupRefreshTimer();

      let loginResponse: loginApiResponse = await loginApi(
        {},
        {
          headers: { Authorization: `Bearer ${idToken}` },
        },
      );
      if (loginResponse && loginResponse.orgs?.length) {
        const currentOrganizationStorage = JSON.parse(
          localStorage.getItem('currentOrganization') as string,
        ) as Organization | undefined;

        // if current org present, update context with latest on current org,
        // else pick first org in orgs list as current org
        const currentOrganization =
          loginResponse.orgs.find(
            (o) => o.id === currentOrganizationStorage?.id,
          ) ||
          loginResponse.orgs[0] ||
          undefined;

        if (currentOrganization) {
          const currentOrgTokens = Object.values(
            (loginResponse.access_tokens || {})[currentOrganization.id],
          );
          const stringifyOrg = JSON.stringify(currentOrganization);
          localStorage.setItem('currentOrganization', stringifyOrg);

          postHogIdentify(
            loginResponse.user?.user_id || '',
            currentOrgTokens[0].account_id || '',
          );
          identify(
            loginResponse.user?.email || '',
            loginResponse.user?.name || '',
            loginResponse.user?.user_id || '',
          );

          updateUserAccountState({
            loginType: 'normal',

            isOrganizationsLoading: false,
            organizations: loginResponse.orgs,
            isCurrentOrgLoading: false,
            currentOrganization: currentOrganization || loginResponse.orgs[0],
            isAccountLoading: false,
            account: {
              token: currentOrgTokens[0].access_token,
              accountId: currentOrgTokens[0].account_id || '',
            },
            user: {
              userId: loginResponse.user?.user_id || '',
              name: loginResponse.user?.name || '',
              email: loginResponse.user?.email,
              profilePicture: loginResponse.user?.profilePicture,
            },
            lastLoginTime: new Date().getTime() / 1000,
            tokens: loginResponse.access_tokens,
          });
        }
        setPendingInvitationSessionStorage(
          loginResponse?.pending_invitations || [],
        );
        const searchParams = new URLSearchParams(window.location.search);

        // Retrieve the 'redirect_to' parameter
        const redirectTo = searchParams.get('redirect_to') || '';

        if (
          loginResponse.pending_invitations?.length &&
          !redirectTo.includes('accept')
        ) {
          const firstInvite = loginResponse.pending_invitations[0];
          window.open(
            `${window.location.origin}/accept/${firstInvite?.invite_key}`,
            '_self',
          );
        }
        return true;
      }
    } catch (err) {
      Sentry.captureException(err);
      updateUserAccountState({
        organizations: [],
        currentOrganization: undefined,
        account: { token: '', accountId: '' },
        user: { userId: '', name: '', email: '' },
        tokens: {},

        isOrganizationsLoading: false,
        isCurrentOrgLoading: false,
        isAccountLoading: false,
        lastLoginTime: new Date().getTime() / 1000,
      });
    }

    return false;
  };

  const setupRefreshTimer = () => {
    // out Id token expire in 30 mins and refresh tokens expire in 1 hour
    // so we should refresh token every 15 mins
    // our chkk access token expires in 1 hour
    const refreshTime = 15 * 60 * 1000;
    setTimeout(refreshToken, refreshTime);
  };

  const refreshToken = async () => {
    try {
      const lastToken = await indexedDBUtil.getToken();
      const refreshTokenStored = lastToken?.refreshToken;
      const tokenDetails = await new Promise<{
        idToken: string;
        accessToken: string;
        refreshToken: string;
        expiresIn: number;
      }>((resolve, reject) => {
        auth0JS.client.oauthToken(
          {
            grantType: 'refresh_token',
            refreshToken: refreshTokenStored,
            redirectUri: `${window.location.origin}/login?redirect_to=/`,
          },
          (err, res) => {
            if (err) {
              reject(err);
              return false;
            } else {
              resolve(
                res as {
                  idToken: string;
                  accessToken: string;
                  refreshToken: string;
                  expiresIn: number;
                },
              );
            }
          },
        );
      });

      indexedDBUtil.setToken({
        idToken: tokenDetails.idToken,
        accessToken: tokenDetails.accessToken,
        refreshToken: tokenDetails.refreshToken,
        expiry: Date.now() + (tokenDetails.expiresIn || 0) * 1000,
      });
      refreshChkkAccessToken(tokenDetails.idToken);
      setupRefreshTimer();
      return true;
    } catch (error) {
      Sentry.captureException(error);
      // TODO: Handle refresh failure (e.g., logout user, redirect to login page)
      return false;
    }
  };

  const refreshChkkAccessToken = async (idToken: string) => {
    let loginResponse: loginApiResponse = await loginApi(
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      },
    );
    if (loginResponse && loginResponse.orgs?.length) {
      const currentOrganizationStorage = JSON.parse(
        localStorage.getItem('currentOrganization') as string,
      ) as Organization | undefined;

      // if current org present, update context with latest on current org,
      // else pick first org in orgs list as current org
      const currentOrganization =
        loginResponse.orgs.find(
          (o) => o.id === currentOrganizationStorage?.id,
        ) ||
        loginResponse.orgs[0] ||
        undefined;

      if (currentOrganization) {
        const currentOrgTokens = Object.values(
          (loginResponse.access_tokens || {})[currentOrganization.id],
        );

        if (loginResponse.access_tokens) {
          updateTokens(loginResponse.access_tokens);
        }
      }
    }
  };

  const logout = async (): Promise<void> => {
    logEvent('logout', {
      userId: user?.userId || '',
      email: user?.email || '',
      name: user?.name || '',
    });
    await indexedDBUtil.removeToken();

    updateUserAccountState({
      loginType: 'normal',

      organizations: [],
      currentOrganization: undefined,
      account: { token: '', accountId: '' },

      isOrganizationsLoading: false,
      isCurrentOrgLoading: false,
      isAccountLoading: false,
    });
  };

  const switchOrganization = async (
    orgId: string,
  ): Promise<[boolean, Organization?]> => {
    if (loginType === 'normal') {
      // Clears SWR cache. All cache keys are updated, Set cache data to `undefined` and do not revalidate until
      // switched org token is set
      await mutate((key) => true, undefined, { revalidate: false });
      // reset previous org's view (example/real) from local storage
      localStorage.removeItem('example-data');

      const currentOrganization = organizations.find((o) => o.id === orgId);
      if (currentOrganization) {
        const currentOrgTokens = Object.values(
          (tokens || {})[currentOrganization.id],
        );
        setCurrentOrgLocalStorage(currentOrganization);
        identify(user?.email || '', user?.name || '', user?.userId || '');
        postHogIdentify(
          user?.userId || '',
          currentOrgTokens[0].account_id || '',
        );

        updateUserAccountState({
          currentOrganization: currentOrganization,
          isCurrentOrgLoading: false,
          account: {
            token: currentOrgTokens[0].access_token,
            accountId: currentOrgTokens[0].account_id || '',
          },
          lastLoginTime: new Date().getTime() / 1000,
        });
        updateTokens(tokens);
        // Mutating again to trigger revalidation for already subscribed endpoints
        // We can not revalidate with the first mutate as the subscribers have previous org token/ data. After SWR
        // cache.key.data is undefined, another mutate needs triggered. Otherwise, for example, clusters endpoint gives
        // undefined.The issue of + Add Cluster showing up was a sideeffect where useListCluster call was not triggered
        // until its refreshInterval cycle
        await mutate((key) => true, undefined, { revalidate: true });
        return [true, currentOrganization];
      } else {
        return [false, undefined];
      }
    } else {
      enqueueSnackbar('Switching orgs is not supported for federated login', {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      return [false, undefined];
    }
  };

  const refreshOrganizations = async (): Promise<boolean> => {
    switch (loginType) {
      case 'normal':
      case 'magiclink':
        return isAuthenticated();
      case 'federated':
        enqueueSnackbar(
          'Refreshing orgs is not supported for federated login',
          { variant: 'warning', autoHideDuration: toastAutoHideDuration },
        );
        return false;
    }
  };

  return {
    loginWithUserToken,
    loginWithFederationToken,
    isAuthenticated,
    logout,
    switchOrganization,
    refreshOrganizations,
    getChkkUserToken,
    setCurrentOrgLocalStorage,
  };
}
